// libraries
import {
  memo,
  useMemo,
  ReactElement,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'
import _ from 'lodash'

// constants
import { GALLERY_LIST_TYPES } from 'constants/common'
import { THEMES } from 'constants/colour'

// components
import {
  DataTable,
  EmptyList,
  Loading,
  EmptySearch,
  ListErrorHandler,
} from 'components/common'

import type { Item, ListConditions } from 'types/common'
import type { DataTableProps } from 'components/common/DataTable'
import type { Entity } from 'types/entity'

// utils
import { sortListByProperty } from 'helpers/utils'
import useFetchList from './hooks/useFetchList'
import useProcessList from './hooks/useProcessList'
import useListItemActions, {
  OnListItemChange,
} from './hooks/useListItemActions'
import useListItemStatus from './hooks/useListItemStatus'
import useListFilter, { useGalleryListFilter } from './hooks/useListFilter'

export {
  useFetchList,
  useListItemActions,
  useProcessList,
  useListFilter,
  useGalleryListFilter,
  useListItemStatus,
}

type ListProps<T extends Entity = Entity> = Partial<DataTableProps<T>> & {
  title: string
  options?: ListConditions
  list?: T[]
  activeItem?: T
  listItem: ReactNode
  tableLoading?: boolean
  onChange: OnListItemChange<T>
  setActiveItem?: (v: T) => void
  setListConditions?: Dispatch<SetStateAction<ListConditions>>
  emptyState: ReactElement
}

const CardsViewList = ({
  list,
  listItem: CardListItem,
  backendSortEnabled,
  sortField,
  ascOrder,
  ...rest
}: Pick<ListProps, 'list' | 'onChange' | 'listItem' | 'backendSortEnabled'> &
  ListConditions): ReactElement => {
  const sortedList = useMemo(
    () =>
      backendSortEnabled || !sortField
        ? list
        : (sortListByProperty({
            list,
            sortField,
            ascOrder,
          }) as Item[]),
    [list, backendSortEnabled, sortField, ascOrder]
  )

  return (
    <>
      {_(sortedList)
        .compact()
        .map(item => <CardListItem {...rest} key={item.id} item={item} />)
        .value()}
    </>
  )
}

const List = memo(
  ({
    title,
    options = {},
    list = [],
    tableLoading,
    emptyState,
    ...rest
  }: ListProps): ReactElement => {
    const {
      ascOrder,
      enableTitle = false,
      listType = GALLERY_LIST_TYPES.card,
    } = options

    const isViewCard = useMemo(
      () => listType === GALLERY_LIST_TYPES.card,
      [listType]
    )

    const commonProps = {
      ...rest,
      ...options,
      list,
    }

    const renderTitle = () => (
      <div className='row gridRow rowHeading'>
        <h4>{title}</h4>
      </div>
    )

    const renderEmptyState = () =>
      options.isSearching ? (
        <EmptySearch />
      ) : (
        <EmptyList content={title} theme={THEMES.light} render={emptyState} />
      )

    const renderListContent = () =>
      isViewCard ? (
        <div className='row g-4 h-auto'>
          <CardsViewList {...commonProps} />
        </div>
      ) : (
        <DataTable
          {...commonProps}
          sortOrder={ascOrder}
          loading={tableLoading}
        />
      )

    return (
      <>
        {enableTitle && renderTitle()}
        {_.isEmpty(list) && !tableLoading
          ? renderEmptyState()
          : renderListContent()}
      </>
    )
  }
)

const ListContainer = ({
  loading,
  onCancel,
  error,
  ...rest
}: ListProps & {
  loading?: boolean
  onCancel?: () => void
  error?: Error
}): ReactElement => (
  <ListErrorHandler error={error}>
    {loading ? (
      <Loading onClick={onCancel} buttonContent='Pause Loading' />
    ) : (
      <List {...rest} />
    )}
  </ListErrorHandler>
)

export default ListContainer
