import { useCallback } from 'react'
import {
  DataGridPro,
  GridRowModel,
  GridRow,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { ThemeProvider } from '@mui/material/styles'

import scss from './index.module.scss'
import type { TreeDataGridProps } from './types'
import { loadingOverlay, useMuiTheme } from '../DataGrid'

const buildTreePath = <T extends GridRowModel>(row: T, data: T[]): string[] => {
  if (!row) return []
  const parent = data.find(r => r?.id === row?.parentId)
  return parent
    ? [...buildTreePath(parent, data), row.id as string]
    : [row.id as string]
}

const COLUMN_HEADER_HEIGHT = 42
const ROW_HEIGHT = 44

const TreeDataGrid = <T extends GridRowModel>({
  loading,
  itemActions = {},
  columns,
  sortField,
  sortOrder,
  currentActiveItem,
  list = [],
  dataKey = 'id',
}: TreeDataGridProps<T>): JSX.Element => {
  const muiTheme = useMuiTheme()

  const CustomRow = useCallback(
    (props: GridRowParams<T>) => (
      <GridRow
        {...props}
        onMouseEnter={() => itemActions.onMouseEnter?.(props)}
        onMouseLeave={() => itemActions.onMouseLeave?.(props)}
      />
    ),
    [itemActions]
  )

  const getRowClassName = useCallback(
    (params: GridRowParams<T>): string => {
      if (params.id === currentActiveItem?.id) return 'Mui-selected'
      return params.row.parentId ? 'tree-child-row' : 'tree-parent-row'
    },
    [currentActiveItem]
  )

  const memoizedBuildTreePath = useCallback(
    (row: T) => buildTreePath(row, list),
    [list]
  )

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={scss.container}>
        <DataGridPro
          rows={list}
          columnHeaderHeight={COLUMN_HEADER_HEIGHT}
          disableCellSelectionOnClick
          rowHeight={ROW_HEIGHT}
          sortingOrder={['asc', 'desc']}
          getRowClassName={getRowClassName}
          initialState={{
            sorting: {
              sortModel: [
                { field: sortField, sort: sortOrder ? 'asc' : 'desc' },
              ],
            },
          }}
          slots={{
            row: CustomRow,
          }}
          slotProps={{
            loadingOverlay,
          }}
          groupingColDef={{
            width: 50,
            headerClassName: 'grouping-column-header',
            resizable: false,
          }}
          onRowClick={itemActions.onRowClick}
          columns={columns}
          loading={loading}
          selectionModel={
            currentActiveItem?.[dataKey] ? [currentActiveItem?.[dataKey]] : []
          }
          getRowId={row => row[dataKey] as string}
          treeData
          hideFooter
          getTreeDataPath={memoizedBuildTreePath}
        />
      </div>
    </ThemeProvider>
  )
}

export default TreeDataGrid
