import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ReactElement,
  forwardRef,
} from 'react'
import _ from 'lodash'
import { ActionMeta } from 'react-select'

// utils
import {
  findOptionsByArrays,
  findOptionByValue,
  findOptionFromGroupedOptionsByValue,
} from 'helpers/filter'
import { getConvertedStringValue } from 'helpers/utils'
import useSelectStyles from 'components/common/MultiSelect/useSelectStyles'

import type {
  SelectOption,
  SelectOptions,
  MultiSelectProp,
  AsyncPaginateAdditional,
} from './types'

import { getMultiSelectCommonProps, getSelectComponent } from './utils'

export {
  getMultiSelectCommonProps,
  SelectOption,
  SelectOptions,
  MultiSelectProp,
  AsyncPaginateAdditional,
}

const optionKey = 'value'
const optionLabel = 'label'

const MultiSelect = forwardRef(
  (
    {
      value,
      options = [],
      onChange,
      isMulti = true,
      group,
      className,
      noDataMessage,
      components,
      preSelect = false,
      bgColour,
      useOptionValueOnly = false,
      creatable = false,
      convertStringType,
      onCreatableOptionsChange,
      formatOptionLabel,
      withBorder = false,
      maxOptionsLength = Infinity,
      removeControl = false,
      name,
      removeInvalidValues = false,
      isLoading = false,
      dropdownOnly = false,
      hasError = false,
      zIndex,
      isDisabled = false,
      isLarge,
      isAsync = false,
      isAsyncPaginate = false,
      readOnly = false,
      menuFitContent,
      hideSelectedOptions = false,
      placeholder = 'Select',
      isClearable = true,
      isSearchable = true,
      windowThreshold = 1000,
      styles,
      ...rest
    }: MultiSelectProp,
    ref
  ): ReactElement => {
    const [selectedOptions, setSelectedOptions] =
      // For an async select, make sure that the selected value is preselected on mount
      useState<SelectOptions | null>(
        isAsync || isAsyncPaginate ? (value as SelectOptions) : null
      )

    const { customStyles } = useSelectStyles({
      bgColour,
      withBorder,
      removeControl,
      dropdownOnly,
      hasError,
      isLarge,
      zIndex,
      readOnly,
      menuFitContent,
    })

    const onChangeHandler = useCallback(
      (option: SelectOption, actionMeta: ActionMeta<SelectOption>) => {
        const { value: newSelectedValue } = option || {}
        const newOption = convertStringType
          ? {
              ...option,
              value: getConvertedStringValue(
                newSelectedValue as string,
                convertStringType
              ),
            }
          : option

        setSelectedOptions(newOption)
        if (_.isFunction(onChange)) {
          const getOptionsValue = () => {
            return isMulti
              ? _.map(newOption, optionKey)
              : newOption?.[optionKey]
          }

          const newValue = useOptionValueOnly ? getOptionsValue() : newOption

          onChange(newValue)
          const { action } = actionMeta
          if (action === 'create-option') {
            onCreatableOptionsChange?.(newValue)
          }
        }
      },
      [
        convertStringType,
        isMulti,
        onChange,
        onCreatableOptionsChange,
        useOptionValueOnly,
      ]
    )

    // using undefined to clear the value is in general bad practice. Use null is recommend when we want to clear the value of any Select component provided by this library.
    // https://github.com/JedWatson/react-select/issues/3066
    useEffect(
      () => {
        // If that's the AsyncSelect, it will handle the value by itself
        if (isLoading || isAsync || isAsyncPaginate) return

        const findSingleOptionValue = (): SelectOption =>
          _.isEmpty(group)
            ? findOptionByValue(options, value, optionKey, preSelect)
            : findOptionFromGroupedOptionsByValue({
                options,
                value,
                group,
                optionKey,
                optionLabel,
              })

        const getOption = () =>
          isMulti
            ? (findOptionsByArrays(options, optionKey, value) as SelectOptions)
            : findSingleOptionValue()

        const isInvalidValue = _.isNil(value) || value === ''

        const option =
          !preSelect && (isInvalidValue || _.isEmpty(options))
            ? null
            : getOption()

        if (preSelect && option && isInvalidValue) {
          onChange(
            useOptionValueOnly && !isMulti
              ? (option as SelectOption).value
              : option
          )
        }

        if (removeInvalidValues) {
          // !Be cautious to use this prop because this would remove the saved value
          if (isMulti) {
            const newOptionValues = _.map(option, 'value')

            if (!_.isEqual(newOptionValues, value)) {
              onChange(useOptionValueOnly ? newOptionValues : option)
            }
          } else if (!_.isNil(value) && option?.value !== value) {
            onChange(useOptionValueOnly ? option?.value : option)
          }
        }
        setSelectedOptions(option)
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        value,
        options,
        group,
        isMulti,
        optionLabel,
        optionKey,
        preSelect,
        isAsync,
        isAsyncPaginate,
      ]
    )

    const Component = useMemo(
      () => getSelectComponent({ creatable, isAsync, isAsyncPaginate }),
      [creatable, isAsync, isAsyncPaginate]
    )

    const validOptions = useMemo(() => {
      return selectedOptions?.length === maxOptionsLength ? [] : options
    }, [maxOptionsLength, options, selectedOptions?.length])

    const isDisabledOrReadOnly = isDisabled || readOnly

    const shouldDisableWhenOnlyOneOption = useMemo(() => {
      if (isMulti) return isDisabledOrReadOnly

      return (
        isDisabledOrReadOnly ||
        (validOptions.length === 1 && selectedOptions === validOptions)
      )
    }, [isMulti, selectedOptions, isDisabledOrReadOnly, validOptions])

    return (
      <Component
        name={name}
        ref={ref}
        isLoading={isLoading}
        loadingMessage={() => 'Loading...'}
        {...getMultiSelectCommonProps({
          isMulti,
          className,
          value,
          maxOptionsLength,
          noDataMessage,
          customStyles,
          components,
          formatOptionLabel,
          readOnly,
        })}
        {...rest}
        isDisabled={shouldDisableWhenOnlyOneOption}
        value={selectedOptions ?? null}
        {...(!isDisabledOrReadOnly && { onChange: onChangeHandler })}
        options={validOptions}
        styles={{ ...customStyles, ...styles }}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        hideSelectedOptions={hideSelectedOptions}
        defaultOptions
        windowThreshold={windowThreshold}
      />
    )
  }
)

export default MultiSelect
