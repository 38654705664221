import _ from 'lodash'
import { matchRoutes } from 'react-router-dom'
import { ROUTE_OPTIONAL_PARAMS_REGEX } from 'constants/common'
import type { Payload } from 'types/common'

const getSearchParamsFromPath = (routePath: string) => {
  // Matching search params /maps/:mapId?edit => ?edit
  const matchResult = routePath.match(/\?[^/:]*$/g)

  const searchParams = matchResult?.[0] || ''
  // Could match only '?' if there is an optional param in the end,
  // so checking if there is more than 1 character matched
  const hasSearchParams = searchParams.length > 1
  // If the route has search params, remove them to get a clean path
  const cleanPath = hasSearchParams
    ? routePath.replace(searchParams, '')
    : routePath

  return { cleanPath, hasSearchParams, searchParams }
}

export const getRouteUrlWithValues = (
  routeUrl = '',
  routeValues?: Payload
): string => {
  const { cleanPath, hasSearchParams, searchParams } =
    getSearchParamsFromPath(routeUrl)

  const newUrl = _.reduce(
    _.omitBy(routeValues, _.isNil),
    (route, value, key) => {
      const param = `:${key}`
      const optionalParam = `${param}?`
      const isOptional = route.includes(optionalParam)
      // If the param is optional, don't forget to replace '?' in the end
      return route.replace(isOptional ? optionalParam : param, value)
    },
    cleanPath
  )

  const optionalLeftover = newUrl.match(ROUTE_OPTIONAL_PARAMS_REGEX)
  // Cleaning up optional params
  if (optionalLeftover?.[0]) {
    return newUrl.replace(optionalLeftover[0], '')
  }

  return hasSearchParams ? `${newUrl}${searchParams}` : newUrl
}

/**
 * Just a shortcut for 'window.history.replaceState'
 * ! window.history.replaceState will bypass React Router's internal mechanisms
 * for detecting route changes, the results from React Router's hooks,
 * like `useParams`, will not return the most up-to-date results
 * * recommend to use React Router's built-in navigation methods(useNavigate).
 */

export const changeRoute = (routeUrl: string, routeValues: Payload<string>) =>
  window.history.replaceState(
    undefined,
    '',
    getRouteUrlWithValues(routeUrl, routeValues)
  )

export const getRouteUrlWithQueryParams = (
  url: string,
  queryParams?: Payload<string>
) => {
  const queryString = new URLSearchParams(queryParams).toString()
  return `${url}${queryString ? `?${queryString}` : ''}`
}

/** See https://reactrouter.com/en/main/utils/match-routes */
export const getRouteParams = (routePath: string) => {
  const [{ params }] = matchRoutes(
    [
      {
        path: routePath,
      },
    ],
    window.location
  ) || [{}]

  return params
}
