import { ReactElement, useCallback } from 'react'
import type { WidgetProps } from '@rjsf/utils'

// components
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import { FileUploader } from 'components/common'
import AttachmentList from 'components/issue/common/AttachmentList'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'
import { MEDIA_FILE_SIZE_LIMIT } from 'constants/fileUpload'

const PdfWidget = ({
  name,
  schema,
  value = [],
  readonly,
  required,
  formContext,
  rawErrors,
  onChange,
  disabled,
}: WidgetProps): ReactElement => {
  const { title, maxItems = 1 } = schema || {}
  const {
    dataCollectionFormMedia,
    isPreview,
    fileUploadingState,
    setMediaToUploadByField,
  } = formContext || {}

  const onUpload = useCallback(
    (files: File[]) => {
      setMediaToUploadByField?.(state => ({ ...state, [name]: files }))
    },
    [name, setMediaToUploadByField]
  )

  const toggleDelete = useCallback(
    (mediaKey: string, isDeleted: boolean) =>
      onChange(
        value.map(item => {
          if (item.mediaKey === mediaKey) return { ...item, isDeleted }
          return item
        })
      ),
    [value, onChange]
  )

  return (
    <WidgetWrapper
      name={name}
      label={title}
      required={required}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      {/* Display uploaded PDF files */}
      <AttachmentList
        fieldValue={value}
        mediaData={dataCollectionFormMedia}
        toggleDelete={toggleDelete}
      />

      <FileUploader
        disabled={disabled || readonly}
        accept={FILE_MIME_TYPE_EXTENSIONS_MAPPING.fileAttachments}
        placeholderText='Drop your file here'
        supportedFormatsText='(Supported files: PDF, TXT, CSV)'
        isSingleFile={false} // Allow multiple files
        onChange={onUpload}
        fileUploadingState={fileUploadingState}
        maxFiles={maxItems}
        maxSize={MEDIA_FILE_SIZE_LIMIT}
        displayClickHereToUpload
      />
    </WidgetWrapper>
  )
}

export default PdfWidget
