import { useCallback, useState, ReactElement } from 'react'
import _ from 'lodash'

// type
import type {
  MediaKey,
  DataCollectionFormMediaView,
  DataCollectionDeletableFormMedia,
  DataCollectionMediaValue,
} from 'types/issue'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/common/Button'

// components
import { Button, Tooltip } from 'components/common'
import { ModalCarousel } from './ModalCarousel'
import Image from './index'

// style
import scss from './index.module.scss'

export type ImageResource = DataCollectionFormMediaView

type RenderImagesOptions = {
  remainingImagesCount?: number
  containerClassName?: string
  imageClassName?: string
}

const getImageUrl =
  (imageResources: ImageResource[]) =>
  (
    v: MediaKey | DataCollectionMediaValue
  ): DataCollectionDeletableFormMedia => {
    return {
      downloadUrl: _.get(
        _.find(imageResources, { mediaKey: _.isString(v) ? v : v.mediaKey }),
        'downloadUrl'
      ),
      ...(_.isString(v) ? { mediaKey: v } : v),
    }
  }

const useImageCarousel = ({
  imageResources = [],
  toggleDelete,
}: {
  imageResources?: ImageResource[]
  toggleDelete?: (mediaKey: string, isDeleted: boolean) => void
}): {
  renderCarousel: () => ReactElement
  renderImages: (
    currentImageValues: string[] | string,
    imageWidth?: number,
    imagesPerRow?: number,
    options?: RenderImagesOptions
  ) => ReactElement
} => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const openLightBox = useCallback(
    (mediaKey: MediaKey) => {
      const imageIndex = _.findIndex(imageResources, { mediaKey })

      setCurrentImage(imageIndex)
      setViewerIsOpen(true)
    },
    [imageResources]
  )

  const closeLightBox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const renderImages = useCallback(
    (
      currentImageValues: [] | string,
      imageWidth = 200,
      imagesPerRow = 2,
      {
        remainingImagesCount = 0,
        containerClassName,
        imageClassName,
      }: RenderImagesOptions = {}
    ) => {
      if (_.isEmpty(currentImageValues)) return <></>

      const getImage = getImageUrl(imageResources)

      const foundMatchedUrls = _.isArray(currentImageValues)
        ? currentImageValues.map(getImage)
        : [getImage(currentImageValues)]

      const displayedImages = _(foundMatchedUrls)
        .compact()
        .filter('downloadUrl')
        .value()

      return (
        <div className='row mt-1 g-2'>
          {displayedImages.map(
            ({ downloadUrl, description, mediaKey, isDeleted }, idx) => {
              const isLastItem = idx === displayedImages.length - 1
              const shouldDisplayRemainingCount =
                isLastItem && remainingImagesCount > 0

              return (
                <div
                  key={mediaKey}
                  className={`${scss.image} ${
                    isDeleted ? scss.deleted : ''
                  } col-${12 / imagesPerRow}`}
                  {...(!_.isEmpty(imageResources) && {
                    onClick: () => openLightBox(mediaKey),
                    style: { cursor: 'pointer' },
                  })}
                >
                  <Image
                    src={downloadUrl}
                    style={{
                      width: `${imageWidth}px`,
                      height: `${(imageWidth * 3) / 4}px`,
                    }}
                    title={description}
                    className={imageClassName}
                    containerClassName={containerClassName}
                    {...(shouldDisplayRemainingCount && {
                      remainingImagesCount,
                    })}
                  />

                  {toggleDelete && (
                    <div className={scss.controls}>
                      <Tooltip
                        placement={TOOLTIP_PLACEMENT.top}
                        overlay={
                          <span>{isDeleted ? 'Restore' : 'Delete'}</span>
                        }
                      >
                        <Button
                          size={BUTTON_SIZES.small}
                          variant={BUTTON_VARIANTS.secondary}
                          iconSize={14}
                          onClick={e => {
                            e.stopPropagation()
                            toggleDelete(mediaKey, !isDeleted)
                          }}
                          icon={isDeleted ? 'UndoIcon' : 'IconTrash'}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              )
            }
          )}
        </div>
      )
    },
    [imageResources, openLightBox, toggleDelete]
  )

  const renderCarousel = useCallback(() => {
    return _.isEmpty(imageResources) ? (
      <></>
    ) : (
      <ModalCarousel
        viewerIsOpen={viewerIsOpen}
        closeLightBox={closeLightBox}
        currentImage={currentImage}
        imageResources={imageResources}
      />
    )
  }, [currentImage, imageResources, viewerIsOpen])

  return { renderCarousel, renderImages }
}

export default useImageCarousel
