// libraries
import { ReactElement, useMemo, useState, useEffect } from 'react'
import { useDropzone, Accept, DropzoneOptions } from 'react-dropzone'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// types
import type { FileTemplate } from 'types/common'

// constants
import {
  FILE_UPLOAD_ERROR_MESSAGES,
  FILE_UPLOAD_STATUS,
} from 'constants/fileUpload'
import { BUTTON_VARIANTS } from 'components/common/Button'

// utils
import { showError, showWarn } from 'helpers/message'

// components
import { Button, IconButton } from 'components/common'

import { FileItem } from './components'
import scss from './index.module.scss'

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export type FileUploadingState = Record<string, keyof typeof FILE_UPLOAD_STATUS>

const FileUploader = ({
  onChange,
  files = [],
  disabled,
  errorMessage,
  isSingleFile = true,
  accept,
  placeholderText,
  supportedFormatsText,
  template,
  uploadMessage,
  displayClickHereToUpload,
  fileUploadingState,
  maxFiles,
  maxSize,
}: Pick<DropzoneOptions, 'maxFiles' | 'maxSize'> & {
  onChange: (v: File[]) => void
  files?: File[]
  disabled?: boolean
  errorMessage?: string
  isSingleFile?: boolean
  accept: Accept
  placeholderText: string
  supportedFormatsText?: string
  template?: FileTemplate
  uploadMessage?: string
  displayClickHereToUpload?: boolean
  fileUploadingState?: FileUploadingState
}): ReactElement => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files)
  const isMaxFilesLimitReached = maxFiles === 0

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject = true,
    isDragActive,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...(maxFiles && { maxFiles }),
    ...(maxSize && { maxSize }),
    ...(isSingleFile
      ? { maxFiles: 1 }
      : {
          multiple: true,
        }),

    /** Works for both drag'n'drop and file selection via the file dialog */
    onDrop: acceptedFiles => {
      if (_.isEmpty(acceptedFiles)) return

      if (isSingleFile) {
        setUploadedFiles(acceptedFiles)
      } else {
        if (isMaxFilesLimitReached) {
          showWarn('You have reached the maximum number of files')
          return
        }

        setUploadedFiles((oldFiles: File[]) => {
          const duplicatedFiles = _.intersectionBy(
            oldFiles,
            acceptedFiles,
            'name'
          )
          if (_.isEmpty(duplicatedFiles)) {
            return [...oldFiles, ...acceptedFiles]
          }
          showError(
            `${duplicatedFiles.length} file(s) have the same name. Remove them or rename the new files and upload again`
          )
          return oldFiles
        })
      }
    },

    onDropRejected: filesInfo =>
      filesInfo.forEach(({ errors, file }) => {
        const origErrorInfo = _.first(errors)
        if (!origErrorInfo) return

        const formattedErrorMessage =
          FILE_UPLOAD_ERROR_MESSAGES[origErrorInfo.code]?.({
            file,
            maxSize,
          }) || `${file.name} – ${origErrorInfo.message}`

        showWarn(formattedErrorMessage)
      }),
    accept,
  })

  const currentFiles = _.map(uploadedFiles, ({ path, name, size }) => {
    const uploadingStatus = fileUploadingState?.[name]

    return (
      <FileItem
        key={name}
        className='m-2'
        name={name}
        path={path}
        size={size}
        uploadingStatus={uploadingStatus}
        hasError={!!errorMessage}
        disabled={disabled}
        onDelete={fileName =>
          setUploadedFiles((oldFiles: File[]) =>
            _.reject(oldFiles, { name: fileName })
          )
        }
      />
    )
  })

  useEffect(() => {
    onChange(uploadedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  const style = useMemo(
    () => ({
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
      ...(isDragActive &&
        isMaxFilesLimitReached && { ...rejectStyle, cursor: 'no-drop' }),
    }),
    [
      isFocused,
      isDragAccept,
      isDragReject,
      isDragActive,
      isMaxFilesLimitReached,
    ]
  )

  const displayPlaceholder = _.isEmpty(currentFiles)

  return (
    <div>
      <div
        {...getRootProps({
          className: `${scss.dropZone} ${
            displayPlaceholder ? scss.placeholder : scss.preview
          }`,
          style,
        })}
      >
        <input {...getInputProps()} />
        {displayPlaceholder ? (
          <div>
            {/* https://github.com/react-dropzone/react-dropzone/issues/1239 */}
            <div className='text-secondary smallText semiBold'>
              {placeholderText}
              {displayClickHereToUpload && (
                <>
                  &nbsp;or&nbsp;
                  <span className={scss.textButton} onClick={open}>
                    click here to upload
                  </span>
                </>
              )}
            </div>

            {supportedFormatsText && (
              <div className='text-secondary smallText'>
                {supportedFormatsText}
              </div>
            )}

            {template && (
              <>
                <div className='text-secondary smallText'>{uploadMessage}</div>
                <Button
                  variant={BUTTON_VARIANTS.link}
                  onClick={() => {
                    saveAs(
                      `${process.env.PUBLIC_URL}${template.filePath}`,
                      `${template.fileName}`
                    )
                  }}
                >
                  DOWNLOAD TEMPLATE
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className='d-flex flex-wrap'>{currentFiles}</div>
        )}
      </div>

      {!!errorMessage && (
        <div className='text-danger'>
          <IconButton
            size={14}
            icon='RiErrorWarningFill'
            className='text-danger'
          />
          <span className='smallText'>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}

export default FileUploader
