import { ReactElement, PropsWithChildren } from 'react'

const FieldDescription = ({
  className = '',
  isLarge,
  hasError,
  children,
}: PropsWithChildren<{
  className?: string
  isLarge?: boolean
  hasError?: boolean
}>): ReactElement => (
  <div
    className={`form-label-description ${isLarge ? 'is-large' : ''} ${
      hasError ? 'is-invalid' : ''
    } ${className}`}
  >
    {children}
  </div>
)

export default FieldDescription
