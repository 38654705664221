import { ReactElement, useMemo } from 'react'

// components
import { FileItem } from 'components/common/FileUploader/components'

import type {
  DataCollectionDeletableFormMedia,
  DataCollectionFormMediaView,
} from 'types/issue'

const AttachmentList = ({
  fieldValue,
  mediaData,
  toggleDelete,
}: {
  fieldValue?: DataCollectionDeletableFormMedia[]
  mediaData?: DataCollectionFormMediaView[]
  toggleDelete?: (mediaKey: string, isDeleted: boolean) => void
}): ReactElement | null => {
  const attachments = useMemo(
    () =>
      fieldValue
        ?.filter(({ mediaKey }) => !!mediaKey)
        .map(({ mediaKey, ...rest }) => {
          const mediaInfo =
            mediaData?.find(
              formMediaItem => formMediaItem.mediaKey === mediaKey
            ) || {}

          // Mixing up with the information from 'dataCollectionFormMedia' that contains 'downloadUrl'
          return { mediaKey, ...rest, ...mediaInfo }
        }) || [],
    [fieldValue, mediaData]
  )

  return attachments.length > 0 ? (
    <div className='d-flex flex-wrap my-2 gap-2'>
      {attachments.map(({ mediaKey, isDeleted, downloadUrl }) => (
        <FileItem
          key={mediaKey}
          name={mediaKey}
          downloadUrl={downloadUrl}
          isDeleted={isDeleted}
          {...(toggleDelete && {
            onDelete: () => toggleDelete?.(mediaKey, !isDeleted),
          })}
        />
      ))}
    </div>
  ) : null
}

export default AttachmentList
