import { ENTITIES } from 'constants/common'
import keymirror from 'keymirror'

export enum MESSAGE_TYPES {
  info = 'info',
  primary = 'primary',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const MESSAGE_ENTITIES = {
  ...ENTITIES,
  [ENTITIES.ancillaryData]: 'data',
}

export const MESSAGE_STATUS = keymirror({
  save: null,
  saved: null,
  saving: null,
  update: null,
  updated: null,
  updating: null,
  delete: null,
  deleted: null,
  deleting: null,
  fetch: null,
  fetched: null,
  fetching: null,
  share: null,
  shared: null,
  sharing: null,
  clone: null,
  cloned: null,
  cloning: null,
  create: null,
  created: null,
  creating: null,
})

export const INCORRECT_TOTP_ERROR_MESSAGE =
  'Incorrect code. Enter the code shown on your security app'

export const INCORRECT_TOTP_ERROR_MESSAGE_WITH_SCAN = `${INCORRECT_TOTP_ERROR_MESSAGE} or try scanning or entering the number again`

export const INCORRECT_SMS_ERROR_MESSAGE =
  'Incorrect code. Enter the code sent to the above mobile phone number or try sending the code again.'
