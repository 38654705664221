import { gql } from '@apollo/client'

export const GET_AVAILABLE_ASSET_TYPES = gql`
  query getAvailableAssetTypes(
    $assetTypeFilter: AssetTypeFilter
    $camAssetFirst: Int
    $canAssetFilter: CamAssetFilter
  ) {
    assetTypes {
      all(filter: $assetTypeFilter) {
        edges {
          node {
            name
            assetTypeId
            assets {
              all(first: $camAssetFirst, filter: $canAssetFilter) {
                edges {
                  node {
                    assetReference
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
