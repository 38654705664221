import keymirror from 'keymirror'
import { makeMethaneAppEntityName } from 'app/MissionControlMethaneSolution/helpers/common'
import { EmissionRateUnitType } from '../types/graphql'

const TAB_DETECTION_SUFFIX = ':tab/:id?'

export const INBOX_URL = `/inbox`
export const WIP_URL = `/wip`
export const DETECTIONS_URL = `/detections`
export const ASSETS_URL = `/assets`
export const RECONCILIATION_URL = `/reconciliation`
export const OGMP2_URL = `/OGMP2`
export const AUDIT_URL = `/audit`
export const DASHBOARD_URL = '/dashboards'
export const CO2E_URL = '/CO2e'
export const EMISSION_OBSERVATIONS_URL = '/emissionObservations'
export const EMISSIONS_URL = '/emissions'
export const EMISSION_URL = '/emissionEvents'

const YEAR_MONTH_OPTIONAL_PATH_PARAMS = `/:year?/:month?/`

const appendYearMonthPathParams = (url: string) =>
  `${url}${YEAR_MONTH_OPTIONAL_PATH_PARAMS}`

export const APP_URLS = {
  EMISSIONS: EMISSIONS_URL,
  EMISSIONS_BY_ASSET_SITE: `${EMISSIONS_URL}/:assetReference/:tab?/:emissionEventId?`,
  EMISSION: `${EMISSION_URL}/:id`,
  EMISSION_OBSERVATIONS: `${EMISSION_OBSERVATIONS_URL}/:id`,
  INBOX: INBOX_URL,
  INBOX_EMISSIONS_BY_ASSET: `${INBOX_URL}/:assetReference/:emissionObservationId?`,
  WIP: WIP_URL,
  WIP_TAB: `${WIP_URL}/${TAB_DETECTION_SUFFIX}`,
  ASSETS: ASSETS_URL,
  ASSET_VIEW: `${ASSETS_URL}/:tab`,
  RECONCILIATION_BASE: RECONCILIATION_URL,
  RECONCILIATION: appendYearMonthPathParams(RECONCILIATION_URL),
  RECONCILIATION_DECISION_LOG: appendYearMonthPathParams(
    `${RECONCILIATION_URL}/decisions`
  ),
  RECONCILIATION_SITE_REPORT: `${RECONCILIATION_URL}/:siteId/:year/:month/:day?/:equipmentId?`,
  DASHBOARDS: DASHBOARD_URL,
  DASHBOARD: `${DASHBOARD_URL}/:dashboardName`,
  AUDIT_BASE: AUDIT_URL,
  AUDIT: appendYearMonthPathParams(AUDIT_URL),
  CO2E_BASE: CO2E_URL,
  CO2E: appendYearMonthPathParams(CO2E_URL),
  OGMP2_BASE: OGMP2_URL,
  OGMP2: appendYearMonthPathParams(OGMP2_URL),
  OGMP2_BY_SITE: `${OGMP2_URL}/assets/:assetReference/:year/:month?/`,
}

export const ITEMS_PER_PAGE = 20

export const LOAD_MORE_ITEMS_PER_PAGE = 100

export const NO_VALUE_PLACEHOLDER = '--'

export const METHANE_OLD_ENTITY_NAMES = keymirror({
  deep_dive: null,
  reconciliation_overview: null,
  decisionLog: null,
  inbox_open_emissions: null,
  inbox_open_emissions_grouped: null,
  detection_wip: null,
  detection_wip_closed: null,
  audit: null,
  executive_dashboard: null,
  co2e: null,
  flyover_dashboard: null,
  emission_event: null,
  emission_event_site_events: null,
  emission_event_site_observations: null,
  dashboards_detections_dashboard: null,
  OGMP2_assets: null,
  OGMP2_report: null,
  OGMP2_by_asset_measurements: null,
})

/** Creating new entity names with prefix */
export const METHANE_ENTITY_NAMES = [
  ...Object.values(METHANE_OLD_ENTITY_NAMES),
  'asset',
].reduce(
  (acc, entityName) => ({
    ...acc,
    // Giving a prefix to each entity
    [entityName]: makeMethaneAppEntityName(entityName),
  }),
  {} as typeof METHANE_OLD_ENTITY_NAMES & { asset: string }
)

/** 14 Nov 2023: Hiding some unfinished features before the MVP release */
export const IS_FEATURE_ENABLED = {
  WIP_EVENT_ACTIVITY: false,
}

export const DETECTION_TYPES = keymirror({
  DETECTION_TYPE_CMS: null,
  DETECTION_TYPE_FLYOVER: null,
  DETECTION_TYPE_OGI: null,
  DETECTION_TYPE_SATELLITE: null,
  DETECTION_TYPE_UNKNOWN: null,
  DETECTION_TYPE_MANUAL: null,
  DETECTION_TYPE_DRONE: null,
  DETECTION_TYPE_OWD: null,
  DETECTION_TYPE_VENTING_FLARING_BLOWDOWN: null,
  NO_DETECTION_TYPE: null,
})

export const SUPER_EMITTERS_EMISSION_RATE_THRESHOLD = 100

export const OPS_COMMENT_DEFAULT_ACTION_NAME = 'Ops Comment'

export const EMISSIONS_UNIT_LABEL = {
  [EmissionRateUnitType.KilogramsPerHour]: 'kg/h',
}

/**
 * Default external ID used for identifying asset.
 * @deprecated This value will be removed after migration is complete and external IDs are added in LD for all customers
 */
export const DEFAULT_ASSET_EXTERNAL_ID = 'FLOC'

/** 7 mb */
export const EMAIL_TOTAL_MAX_ATTACHMENT_SIZE_IN_BYTES = 7000000
