import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'

import { useConfigStateValue } from 'contexts'

import type { FeatureFlag } from 'types/common'

export const initialEnabledFeatureFlagState = {
  rulesEngine: true,
  tileLayer: false,
  issue: true,
  site: false,
  asset: true,
  maps: true,
  workflowGeofenceAncillaryData: false,
  dynamicScheduling: false,
  jsonForm: true,
  createUser: true,
  ldarSimFileUploader: false,
  jsonFormListBuilder: false,
  loginRedirectMethane: false,
  displayVentingEventLocationOnMapInGems: false,
  displayQubeAlarmLocationOnMapInGems: false,
  createAnIssueFromAnActionOnADetectionInAnInbox: true,
  loginRedirectUrl: '/',
}

const useFeatureFlag = (
  initEnableFlags: Partial<FeatureFlag> = initialEnabledFeatureFlagState
): FeatureFlag => {
  const { featureFlags } = useConfigStateValue()

  const launchDarklyFlags = useFlags()

  return useMemo(() => {
    return {
      ...initEnableFlags,
      ...featureFlags,
      ...launchDarklyFlags,
    }
  }, [initEnableFlags, featureFlags, launchDarklyFlags])
}

export default useFeatureFlag
