// libraries
import _ from 'lodash'
import {
  jsonToGraphQLQuery,
  VariableType,
  EnumType,
} from 'json-to-graphql-query'

// constants
import { AUTH_ERROR_CODES, GRAPHQL_ERROR_PREFIX } from 'constants/common'

// utils
import { reportErrors } from 'helpers/log'

import type { Payload } from 'types/common'
import type { User } from 'types/user'
import type { SelectedFields } from 'types/services'
import type { MutationError, PageInfo } from 'types/graphql'

type InlineFragments = { __typeName: string }

type Node = { node: Payload }

class SuGraphqlError extends Error {
  query: string | undefined

  variables: Payload | undefined

  code: string | undefined

  constructor({
    error,
    domain,
    fnName,
    query,
    variables,
    code,
  }: {
    error: Error | string
    domain?: string
    fnName?: string
    query?: string
    variables?: Payload
    code?: string
  }) {
    const prefix = `${_.compact([domain, fnName]).join('-')}`
    const messagePrefix = prefix ? `[${prefix}] ` : ''
    const errorMessage = _.isObject(error) ? error.message : error
    const message = `${messagePrefix}${errorMessage}`
    super(message)
    this.code = code
    this.query = query
    this.variables = variables
    this.name = GRAPHQL_ERROR_PREFIX
  }
}

export { SuGraphqlError }

export const reportGraphqlError =
  (domain: string) =>
  ({
    error,
    fnName,
    query,
    variables,
    code,
  }: Partial<{
    error: Error | string
    fnName: string
    query: string
    variables: Payload
    code: string
  }>): void => {
    if (!error) return

    const extras = { error, query, variables, code }
    reportErrors(new SuGraphqlError({ ...extras, domain, fnName }), extras)
  }

export const getRelayEdgesData = <T>(
  data?: unknown,
  path: string[] = []
): T[] => {
  return (
    _(_.get(data, [...path, 'edges']))
      .map('node')
      .compact()
      .value() || []
  )
}

export const getGraphqlQuery = (query: Payload): string => {
  return jsonToGraphQLQuery(query, { pretty: true })
}

export const getNode = (payload: Payload): Node => {
  return {
    node: payload,
  }
}

export const getEdges = (payload: Payload): { edges: Node } => {
  return {
    edges: getNode(payload),
  }
}

export const getPageInfo = (
  pageInfo: PageInfo = {}
): { pageInfo: PageInfo } => {
  return {
    pageInfo: {
      hasNextPage: true,
      endCursor: true,
      ...pageInfo,
    },
  }
}

export const ARGS_KEY = '__args'

export const getArgs = (payload?: Payload): { [ARGS_KEY]?: Payload } =>
  _.isEmpty(_.omitBy(payload, _.isNil)) ? {} : { [ARGS_KEY]: payload }

const getQueryName = (name?: string) =>
  name && {
    __name: _.upperFirst(_.camelCase(name)),
  }

export const getVariables = (
  variables?: Payload
):
  | {
      __variables: Payload
    }
  | undefined => {
  return (
    variables && {
      __variables: variables,
    }
  )
}

export const getGraphqlResponseError = (
  response: Payload,
  verbose = true
): string => {
  const errorMessage = _.get(response, 'errors[0].message') as string
  if (!verbose) return errorMessage

  const stacktrace = _.get(
    response,
    'errors[0].extensions.exception.stacktrace',
    []
  ) as []
  return _.take(stacktrace, 2).join() || errorMessage
}

export const getGraphqlResponseErrorCodes = (response: Payload): string[] => {
  const { errors } = response || {}

  return _(errors)
    .map(({ extensions }) => extensions?.code)
    .compact()
    .value()
}

export const hasUnauthenticatedError = (response: Payload): boolean => {
  const errorCodes = getGraphqlResponseErrorCodes(response)
  if (_.isEmpty(errorCodes)) return false

  return _.includes(errorCodes, AUTH_ERROR_CODES.UNAUTHENTICATED)
}

export const findUser = (users?: User[], username?: string): User =>
  (username
    ? _.find(users, { username }) || {
        username,
      }
    : {}) as User

export const getQuery = (query: Payload, name?: string): string => {
  return getGraphqlQuery({
    query: { ...query, ...getQueryName(name) },
  })
}

export const getVariableArgs = (
  variables: Payload<string | null>
): ReturnType<typeof getArgs> =>
  getArgs(_.mapValues(variables, (v, k) => new VariableType(v ?? k)))

export const convertVariablesToArgs = (
  variables?: Payload<string>
): Payload<VariableType> | undefined =>
  _.isEmpty(variables)
    ? undefined
    : _.mapValues(variables, (_v, key) => new VariableType(key))

export const getMutationQuery = ({
  mutationName = 'mutation',
  variableKey,
  variableFormat,
  fields = {},
  args,
  variables,
}: {
  mutationName?: string
  variableKey?: string | null
  variableFormat?: string
  fields?: Payload
  args?: Payload
  variables?: Payload<string>
}): string => {
  if (!mutationName) throw new Error(`mutationName is missing`)

  // TODO: enable it to support multiple variables (like getEntitiesQuery), pass variables ({key:format}) instead of the combination of variableKey and variableFormat, need to review all existing mutations
  const useVariables = variables || !!(variableKey && variableFormat)
  const argsPayload = variables
    ? undefined
    : variableKey
    ? { [variableKey]: useVariables ? new VariableType(variableKey) : args }
    : args

  return getGraphqlQuery({
    mutation: {
      ...getQueryName(mutationName),
      ...getVariables(
        useVariables
          ? variables || {
              [variableKey as string]: variableFormat,
            }
          : undefined
      ),
      [mutationName]: {
        ...getArgs(argsPayload),
        ...fields,
      },
    },
  })
}

export const getQueryFields =
  (fields: Payload) =>
  ({ omitFields = [], pickFields }: SelectedFields = {}): Payload => {
    return pickFields ? _.pick(fields, pickFields) : _.omit(fields, omitFields)
  }

export const listEnumTypeDecorator = (values: string[]): EnumType[] =>
  values.map((v: string) => new EnumType(v))

export const mapTypeNameFields = (
  fields: Record<string, boolean | Payload>,
  __typeName: string
): InlineFragments => {
  return {
    __typeName,
    ...fields,
  }
}

export const getInlineFragments = (
  typeNameFieldsMapping: Record<string, Payload>
): { __on: InlineFragments[] } => {
  return { __on: _.map(typeNameFieldsMapping, mapTypeNameFields) }
}

export const getMutationErrorMessage = (
  errors: MutationError[]
): string | undefined => _.get(_.first(errors), 'message')

export const getFields = (fields: string[], prefix: string): string[] =>
  _.map(fields, d => `${prefix}.${d}`)
