// libraries
import {
  useMemo,
  useState,
  useCallback,
  ReactElement,
  useEffect,
  PropsWithChildren,
} from 'react'
import _ from 'lodash'
import { ensuredForwardRef, useAsync } from 'react-use'
import { useRecoilValue } from 'recoil'
import styled from '@emotion/styled/macro'
import { css, SerializedStyles } from '@emotion/react'

// constants
import {
  issueTaskDataCollectionXFormsState,
  IssueTaskForms,
} from 'recoilStore/issuesStore'
import { SUB_TASK_OPTIONS } from 'helpers/issue'

// components
import { MultiSelect } from 'components/common'
import { MdKeyboardArrowDown } from 'components/icons'

import type {
  IssueDataCollectionFormFilter,
  IssueStatus,
  FilterQuestion,
  FilterQuestionItem,
} from 'types/issue'

// utils
import { getConnectedStringFromArray } from 'helpers/utils'
import { useFetchAppSupportData } from 'contexts/hooks'
import useFiltersDropdown from '../hooks/useFiltersDropdown'

import scss from '../index.module.scss'

const shadow = 'hsla(218, 50%, 10%, 0.1)'

type StyledMenuProps = {
  isRightAligned?: boolean
  removeDropdownTopSpacing?: boolean
}

export const getMenuStyles = ({
  isRightAligned,
  removeDropdownTopSpacing,
}: StyledMenuProps = {}): SerializedStyles => css`
  position: absolute;
  ${isRightAligned ? 'right: 0;' : ''}
  min-width: 260px;
  margin-top: ${removeDropdownTopSpacing ? 0 : 8}px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${shadow}, 0 4px 11px ${shadow};
  background-color: white;
  z-index: 1000;
`

export const Menu = styled.div<StyledMenuProps>`
  ${props => getMenuStyles(props)}
`

export const Blanket = styled.div(() => ({
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1,
}))

const Section = styled.section`
  margin-top: 6px;
  margin-bottom: 6px;
`

type Option = {
  displayName: string
} & FilterQuestionItem

type InternalFormFilters = {
  formReference?: Option
  taskStatus?: Option[]
  formResponses?: {
    [key: string]: Option
  }
}

export const SelectDropdown = ensuredForwardRef(
  (
    {
      children,
      isOpen,
      target,
      onClose,
      className,
      isRightAligned = false,
      removeDropdownTopSpacing = false,
    }: PropsWithChildren<{
      isOpen: boolean
      onClose: () => void
      target: ReactElement
      className: string
      isRightAligned?: boolean
      removeDropdownTopSpacing?: boolean
    }>,
    ref
  ) => {
    return (
      <div style={{ position: 'relative' }} className={className} ref={ref}>
        {target}
        {isOpen && (
          <Menu
            isRightAligned={isRightAligned}
            removeDropdownTopSpacing={removeDropdownTopSpacing}
          >
            {children}
          </Menu>
        )}
        {isOpen && <Blanket onClick={onClose} />}
      </div>
    )
  }
)

const formatOptionLabel = ({
  displayName,
  label,
}: {
  displayName: string
  label: string
}) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex' }} key={label}>
      <span>{displayName}</span>
      <span className='ms-2'>{label}</span>
    </div>
  )
}

export const getDeserializedFormFilters = (
  filters: IssueDataCollectionFormFilter,
  xFormsSpecs: IssueTaskForms
): InternalFormFilters => {
  const { formReference, taskStatus, formResponses } = filters || {}
  if (!formReference) return {} as InternalFormFilters

  const currentSpecs = xFormsSpecs[formReference]
  if (!currentSpecs) return {} as InternalFormFilters

  return {
    formReference: {
      label: currentSpecs.title,
      value: formReference,
      displayName: 'Type',
    },
    taskStatus: _(taskStatus)
      .map(status => _.find(SUB_TASK_OPTIONS, { value: status }) as Option)
      .compact()
      .value(),
    formResponses: _.reduce(
      formResponses,
      (acc, cur) => {
        const findQuestions = _.find(currentSpecs.filterQuestions, {
          xFormRef: cur.xFormRef,
        }) as FilterQuestion

        return findQuestions
          ? {
              ...acc,
              [cur.xFormRef]: {
                displayName: findQuestions.displayName,
                value: cur.response,
                label: _.find(findQuestions.items, { value: cur.response })
                  ?.label,
              },
            }
          : acc
      },
      {}
    ),
  }
}

export const getSerializedFormFilters = (
  formFilters: InternalFormFilters
): IssueDataCollectionFormFilter => {
  const { formResponses, taskStatus, formReference } = formFilters || {}

  const newFormResponses = _.compact(
    _.map(
      formResponses,
      (response, key) =>
        response && {
          xFormRef: key,
          response: response.value,
        }
    )
  )

  const newTaskStatus = _.map(taskStatus, 'value') as IssueStatus[]
  return {
    ...(!_.isEmpty(newTaskStatus) && { taskStatus: newTaskStatus }),
    ...(!_.isEmpty(newFormResponses) && { formResponses: newFormResponses }),
    ...(!_.isEmpty(formReference?.value) && {
      formReference: formReference?.value,
    }),
  }
}
const FORM_FILTER_ORDER = {
  formReference: 1,
  taskStatus: 2,
  formResponses: 3,
}

const FilterForm = ({
  value,
  onChange,
  title,
  vertical = false,
}: {
  key: string
  value: IssueDataCollectionFormFilter
  onChange: (v?: IssueDataCollectionFormFilter) => void
  title: string
  vertical: boolean
}): ReactElement => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)
  const { fetchIssueTaskDataCollectionFormMetadata } = useFetchAppSupportData()

  const state = useAsync(async () => {
    await fetchIssueTaskDataCollectionFormMetadata(false)
  }, [])

  const xFormsSpecs = useRecoilValue(issueTaskDataCollectionXFormsState)
  const [formFilters, setFormFilters] = useState<InternalFormFilters>(() =>
    getDeserializedFormFilters(value, xFormsSpecs)
  )

  useEffect(() => {
    const newFormFilters = getDeserializedFormFilters(value, xFormsSpecs)
    setFormFilters(oldFormFilters =>
      _.isEqual(newFormFilters, oldFormFilters)
        ? oldFormFilters
        : newFormFilters
    )
  }, [xFormsSpecs, value])

  const { formReference, taskStatus, formResponses } = formFilters || {}

  const resetFormFilters = useCallback(() => {
    setFormFilters(getDeserializedFormFilters(value, xFormsSpecs))
    toggleOpen(false)
  }, [xFormsSpecs, toggleOpen, value])

  useEffect(() => {
    if (_.isEmpty(formReference)) return
    const result = getSerializedFormFilters(formFilters)

    if (_.isEqual(result, value)) return

    onChange(_.omitBy(result, _.isNil))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFilters])

  const formTypeOptions = useMemo(() => {
    return _.map(xFormsSpecs, ({ id, title: label }) => ({
      label,
      value: id,
      displayName: 'Type',
    }))
  }, [xFormsSpecs])

  const currentSpecs = useMemo(
    () => xFormsSpecs[formReference?.value || ''],
    [formReference, xFormsSpecs]
  )

  const renderQuestions = useCallback(() => {
    return _.map(currentSpecs?.filterQuestions, question => {
      const { displayName, items, xFormRef } = question
      const options = _.map(items, item => ({
        ...item,
        displayName,
      }))

      return (
        <Section key={xFormRef}>
          <MultiSelect
            withBorder
            value={formResponses?.[xFormRef]?.value}
            options={options}
            onChange={(option: Option) => {
              setFormFilters(oldFormFilters => ({
                ...oldFormFilters,
                formResponses: {
                  ...oldFormFilters.formResponses,
                  [xFormRef]: option,
                },
              }))
            }}
            isMulti={false}
            isClearable
            placeholder={displayName}
            formatOptionLabel={formatOptionLabel}
          />
        </Section>
      )
    })
  }, [currentSpecs?.filterQuestions, formResponses])

  const selectedOptionsBadges = useMemo(() => {
    const { formResponses: selectedFormResponses, ...restFormFilters } =
      formFilters || {}
    const validFilters = _.merge({}, selectedFormResponses, restFormFilters)

    const labels = _(validFilters)
      .map((filter, key) => {
        return {
          key,
          filter,
          order: FORM_FILTER_ORDER[key],
        }
      })
      .sortBy('order')
      .map(({ filter, key }) => {
        if (_.isEmpty(filter)) return undefined
        return _.isArray(filter)
          ? `${_.first(filter)?.displayName}: ${getConnectedStringFromArray(
              _.map(filter, 'label')
            )}`
          : `${filter.displayName}: ${filter.label}`
      })
      .compact()
      .value()

    return _.isEmpty(labels) ? '' : labels.join(', ')
  }, [formFilters])

  const taskStatusValues = useMemo(
    () => _.map(taskStatus, 'value'),
    [taskStatus]
  )

  return (
    <div
      className={`d-flex align-items-center flex-wrap ${scss.anchor} `}
      style={{
        marginRight: vertical ? '0' : '10px',
      }}
    >
      <SelectDropdown
        isOpen={isOpen}
        onClose={() => {
          resetFormFilters()
        }}
        ref={ref}
        target={
          <div
            className={`d-flex align-items-center ${scss.button} `}
            type='button'
            onClick={() => toggleOpen(true)}
          >
            <span>{title}: </span>
            <span className={scss.buttonSelected}>{selectedOptionsBadges}</span>
            <MdKeyboardArrowDown className='ms-2' size={16} />
          </div>
        }
      >
        <div style={{ minWidth: '400px' }}>
          <div style={{ margin: '12px' }}>
            <Section>
              <MultiSelect
                withBorder
                value={formReference?.value}
                options={formTypeOptions}
                onChange={(option: Option) => {
                  setFormFilters(oldFormFilters => ({
                    ...oldFormFilters,
                    formReference: option,
                    formResponses: undefined,
                  }))
                }}
                isMulti={false}
                isClearable={false}
                placeholder='Type'
                formatOptionLabel={formatOptionLabel}
                isLoading={state.loading}
              />
            </Section>
            {!_.isEmpty(formReference?.value) && (
              <Section>
                <MultiSelect
                  withBorder
                  value={taskStatusValues}
                  options={SUB_TASK_OPTIONS}
                  onChange={(options: Option[]) => {
                    setFormFilters(oldFormFilters => ({
                      ...oldFormFilters,
                      taskStatus: options,
                    }))
                  }}
                  isMulti
                  isClearable={false}
                  placeholder='Status'
                  formatOptionLabel={formatOptionLabel}
                />
              </Section>
            )}
            {renderQuestions()}
          </div>
        </div>
      </SelectDropdown>
    </div>
  )
}

export default FilterForm
