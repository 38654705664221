// libraries
import _ from 'lodash'
import { ReactElement, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled/macro'

// constants
import { PAGE_HEADER_TAB_POSITION } from 'constants/common'

// components
import { Tabs } from 'components/common'

import type { PageHeaderTabPosition } from 'types/common'
import type { TabsProps } from '../Tabs'

import scss from './index.module.scss'

export type PageHeaderTabsProps = TabsProps & {
  tabsPosition: PageHeaderTabPosition
}

const StyledContainer = styled.div<{
  withTabs: boolean
}>`
  ${({ withTabs }) => (withTabs ? `padding-bottom: 0px;` : '')}
`

export type PageHeaderProps = {
  iconName?: ReactNode
  iconComponent?: ReactNode
  titleComponent: ReactNode
  subtitleComponent?: ReactNode
  rightComponent?: ReactNode
  titleAddonComponent?: ReactNode
  className?: string
  children?: ReactNode
  contentComponent?: ReactNode
  toolbarComponent?: ReactNode
  tabsProps?: PageHeaderTabsProps
  titleFullWidth?: boolean
}

const PageHeader = ({
  iconName,
  className,
  children,
  iconComponent,
  titleComponent,
  titleAddonComponent,
  subtitleComponent,
  rightComponent,
  contentComponent = null,
  toolbarComponent,
  tabsProps,
  titleFullWidth,
}: PageHeaderProps): ReactElement => {
  const { tabsPosition, tabs } = tabsProps || {}

  const withTabs = useMemo(
    () => !_.isEmpty(tabs) && tabsPosition === PAGE_HEADER_TAB_POSITION.left,
    [tabs, tabsPosition]
  )

  return (
    <header className='sticky-top flex-md-nowrap'>
      {titleComponent && (
        <StyledContainer
          className={`d-flex align-items-center justify-content-between ${scss.container} ${className}`}
          withTabs={withTabs}
        >
          <div className='flex-grow-1'>
            <div className='d-flex align-items-center'>
              {iconComponent}
              <div className='flex-grow-1'>
                <div className='d-flex flex-grow-1 align-items-center'>
                  <h1
                    className={`${scss.title} ${titleFullWidth ? 'w-100' : ''}`}
                  >
                    {iconName}
                    {titleComponent}
                  </h1>
                  {titleAddonComponent}
                </div>
                {withTabs ? <Tabs {...tabsProps} /> : <>{subtitleComponent}</>}
              </div>
            </div>
          </div>
          {!_.isEmpty(tabs) &&
            tabsPosition === PAGE_HEADER_TAB_POSITION.middle && (
              <Tabs className={scss.center} {...tabsProps} />
            )}
          <div className='d-flex align-items-center'>
            {children}
            {rightComponent}
          </div>
        </StyledContainer>
      )}
      {contentComponent}
      {toolbarComponent && (
        <div className={scss.toolbar}>{toolbarComponent}</div>
      )}
    </header>
  )
}

export default PageHeader
