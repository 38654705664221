import { BADGE_TYPES } from 'constants/common'
import keymirror from 'keymirror'
import _ from 'lodash'

// constants
import { DATE_RANGE_FILTER_VALUES } from 'constants/filter'

import type { Option } from 'types/common'
import type { DateFilterOptions } from 'types/filter'

// utils
import { transformDateFilterValue } from 'helpers/filter'

import { DETECTION_TYPES } from './common'
import AssetSitesFilterAsyncDropdown, {
  getAssetOptions,
} from '../components/AssetSitesFilterAsyncDropdown'

export const COMPLIANCE_SEVERITY_TYPES = keymirror({
  HIGH: null,
  MEDIUM: null,
  LOW: null,
})

export const MANUAL_FORM_DETECTION_TYPE_LABEL = 'Manual'

export const UNKNOWN_DETECTION_TYPE_LABEL = 'Other'

const BASE_DETECTION_TYPE_OPTIONS = [
  {
    label: 'CMS',
    value: DETECTION_TYPES.DETECTION_TYPE_CMS,
  },
  {
    label: 'Drone',
    value: DETECTION_TYPES.DETECTION_TYPE_DRONE,
  },
  {
    label: 'Flyover',
    value: DETECTION_TYPES.DETECTION_TYPE_FLYOVER,
  },
  {
    label: 'OGI',
    value: DETECTION_TYPES.DETECTION_TYPE_OGI,
  },
  {
    label: 'On-site Walk Down',
    value: DETECTION_TYPES.DETECTION_TYPE_OWD,
  },
  {
    label: 'Satellite',
    value: DETECTION_TYPES.DETECTION_TYPE_SATELLITE,
  },
]

export const DETECTION_TYPE_OPTIONS = [
  ...BASE_DETECTION_TYPE_OPTIONS,
  {
    label: UNKNOWN_DETECTION_TYPE_LABEL,
    value: DETECTION_TYPES.DETECTION_TYPE_UNKNOWN,
  },
]

export const EMISSION_OBSERVATION_TYPE_OPTIONS = [
  ...BASE_DETECTION_TYPE_OPTIONS,
  {
    label: 'VFB',
    value: DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN,
  },
  {
    label: UNKNOWN_DETECTION_TYPE_LABEL,
    value: DETECTION_TYPES.DETECTION_TYPE_UNKNOWN,
  },
]

export const DETECTION_TYPE_ALL_TYPES_VALUE = 'all'

export const EMISSION_OBSERVATION_TYPE_WITH_ALL_OPTIONS = [
  { value: DETECTION_TYPE_ALL_TYPES_VALUE, label: 'ALL TYPES' },
  ...EMISSION_OBSERVATION_TYPE_OPTIONS,
]

export const DETECTION_TYPE_KEY_BY_VALUE = _.keyBy(
  EMISSION_OBSERVATION_TYPE_OPTIONS,
  'value'
)

export const DETECTION_PURPOSES = keymirror({
  DETECTION_PURPOSE_FOLLOWUP: null,
  DETECTION_PURPOSE_REPAIR: null,
  DETECTION_PURPOSE_SCHEDULED: null,
  DETECTION_PURPOSE_VERIFICATION: null,
})

export const DETECTION_PURPOSE_OPTIONS = [
  {
    label: 'Follow-up',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_FOLLOWUP,
  },
  {
    label: 'Repair',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_REPAIR,
  },
  {
    label: 'Scheduled',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_SCHEDULED,
  },
  {
    label: 'Verification',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_VERIFICATION,
  },
]

export const DETECTION_STATUS = keymirror({
  DETECTION_STATUS_NEW: null,
  DETECTION_STATUS_PENDING: null,
  DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED: null,
  DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED: null,
  DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED: null,
  DETECTION_STATUS_OGI_REQUESTED: null,
  DETECTION_STATUS_OGI_SCHEDULED: null,
  DETECTION_STATUS_OGI_COMPLETED: null,

  DETECTION_STATUS_DRONE_REQUESTED: null,
  DETECTION_STATUS_DRONE_SCHEDULED: null,
  DETECTION_STATUS_DRONE_COMPLETED: null,

  DETECTION_STATUS_LEAK_REPAIR_SCHEDULED: null,
  DETECTION_STATUS_LEAK_REPAIRED: null,
  DETECTION_STATUS_VERIFICATION_SCHEDULED: null,
  DETECTION_STATUS_RESOLVED: null,
  DETECTION_STATUS_VFB_REPORT_REQUESTED: null,
  DETECTION_STATUS_PENDING_RESOLUTION: null,
})

export const DETECTION_STATUS_LABELS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 'New',
  [DETECTION_STATUS.DETECTION_STATUS_PENDING]: 'Analysis',

  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]:
    'Visual inspection requested',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]:
    'Visual inspection scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]:
    'Visual inspection completed',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 'OGI requested',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 'OGI scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 'OGI completed',

  [DETECTION_STATUS.DETECTION_STATUS_DRONE_REQUESTED]: 'Drone requested',
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_SCHEDULED]: 'Drone scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_COMPLETED]: 'Drone completed',

  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]:
    'Leak repair scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 'Leak repaired',
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]:
    'Verification scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 'Completed',
  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 'Investigation',
  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 'In Progress',
}

export const WORK_ITEM_LABELS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 'VFB report',

  [DETECTION_STATUS.DETECTION_STATUS_PENDING]: '',

  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]: 'VFB report',

  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 'OGI',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 'OGI',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 'OGI',

  [DETECTION_STATUS.DETECTION_STATUS_DRONE_REQUESTED]: 'Drone',
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_SCHEDULED]: 'Drone',
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_COMPLETED]: 'Drone',

  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]: 'Leak',
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 'Leak',
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]: 'Leak',

  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 'VFB report',
}

export const PROGRESS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 0,
  [DETECTION_STATUS.DETECTION_STATUS_PENDING]: 0,

  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]: 40,
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]: 50,
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]: 60,

  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 20,
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 40,
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 50,

  [DETECTION_STATUS.DETECTION_STATUS_DRONE_REQUESTED]: 20,
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_SCHEDULED]: 40,
  [DETECTION_STATUS.DETECTION_STATUS_DRONE_COMPLETED]: 50,

  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]: 80,
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 100,
  // There is no 'verification' status for OXY for now,
  // but keeping the constant here in case we need it in the future
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]: 90,

  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 95,
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 100,

  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 30,
}

export const COMPLIANCE_BADGE_TYPE_MAPPING = {
  [COMPLIANCE_SEVERITY_TYPES.HIGH]: BADGE_TYPES.error,
  [COMPLIANCE_SEVERITY_TYPES.MEDIUM]: BADGE_TYPES.warning,
  [COMPLIANCE_SEVERITY_TYPES.LOW]: BADGE_TYPES.success,
}

export const WORK_ITEM_BADGE_TYPE_MAPPING = {
  'VFB report': BADGE_TYPES.warning,
  OGI: BADGE_TYPES.infoBlue,
  Drone: BADGE_TYPES.primary,
  Leak: BADGE_TYPES.infoGrey,
}

export const INSPECTION_TYPES = keymirror({
  INSPECTION_OGI: null,
  INSPECTION_VISUAL: null,
})

export const RESOLUTION_MODE = keymirror({
  DETECTION_RESOLVED_CORRELATED: null,
  DETECTION_RESOLVED_FALSE_ALARM: null,
  DETECTION_RESOLVED_READY_TO_RECONCILE: null,
})

export const RESOLUTION_MODE_MAPPING = {
  [RESOLUTION_MODE.DETECTION_RESOLVED_CORRELATED]: 'Correlated',
  [RESOLUTION_MODE.DETECTION_RESOLVED_FALSE_ALARM]: 'False alarm',
  [RESOLUTION_MODE.DETECTION_RESOLVED_READY_TO_RECONCILE]: 'Closed',
}

export const EMISSION_RATE_UNITS = keymirror({
  kg: null,
  scf: null,
})

export const EMISSION_RATE_UNIT_LABELS = {
  [EMISSION_RATE_UNITS.kg]: 'kg/h',
  [EMISSION_RATE_UNITS.scf]: 'scf/h',
}

export const EMISSION_RATE_UNIT_OPTIONS = [
  { value: EMISSION_RATE_UNITS.kg, label: EMISSION_RATE_UNIT_LABELS.kg },
  { value: EMISSION_RATE_UNITS.scf, label: EMISSION_RATE_UNIT_LABELS.scf },
]

/** 1 scf = 0.01919 kg */
export const SCF_TO_KG_CONVERSION_FACTOR = 0.01919

export const DETECTION_LIST_FILTER_TYPES = keymirror({
  siteId: null,
  assetReference: null,
  acknowledged: null,
  detectionType: null,
  detectedAfter: null,
  detectedBefore: null,
  dacSite: null,
  shownStatuses: null,
})

const DETECTION_DATE_FILTER_COMMON_SPEC = {
  getFilterValue: transformDateFilterValue,
  enable: false,
  // Prevent from cleaning it up when user is clicking "Clear" on the filters panel
  isClearable: false,
}

export const DETECTION_DATE_FILTER_SPECS = [
  {
    ...DETECTION_DATE_FILTER_COMMON_SPEC,
    key: DETECTION_LIST_FILTER_TYPES.detectedAfter,
  },
  {
    ...DETECTION_DATE_FILTER_COMMON_SPEC,
    key: DETECTION_LIST_FILTER_TYPES.detectedBefore,
  },
]

export const DETECTION_DATE_FILTER_SPECS_KEY_BY_KEY = _.keyBy(
  DETECTION_DATE_FILTER_SPECS,
  'key'
)

export const DAC_SITE_FILTER_SPEC = {
  key: DETECTION_LIST_FILTER_TYPES.dacSite,
  label: 'DAC Sites',
  icon: 'MethaneInboxIcon',
  isPureBackendFilter: true,
  isMulti: false,
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
}

export const ASSET_SITES_FILTER_SPEC = {
  key: DETECTION_LIST_FILTER_TYPES.assetReference,
  label: 'Asset name',
  icon: 'MethaneSitesIcon',
  isPureBackendFilter: true,
  component: AssetSitesFilterAsyncDropdown,
  getFilterValue: (options: Option[]) => {
    return _.map(getAssetOptions(options), 'value')
  },
}

export const DETECTION_FILTER_SPECS = [
  DAC_SITE_FILTER_SPEC,
  ASSET_SITES_FILTER_SPEC,
  {
    key: DETECTION_LIST_FILTER_TYPES.acknowledged,
    label: 'Is read',
    icon: 'MethaneInboxIcon',
    isPureBackendFilter: true,
    isMulti: false,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: DETECTION_LIST_FILTER_TYPES.detectionType,
    label: 'Emission observation type',
    isPureBackendFilter: true,
    isMulti: true,
    icon: 'MethaneManualIcon',
    options: EMISSION_OBSERVATION_TYPE_OPTIONS,
  },
  ...DETECTION_DATE_FILTER_SPECS,
]

/** ENUM representation of fields that can be used to sort the detections query */
export const DETECTION_SORT_BY_FIELD_PARAMS = keymirror({
  DETECTED_AT: null,
  EMISSIONS_RATE: null,
  SHORT_ID: null,
  LAST_UPDATED: null,
})

/** Field key to ENUM */
export const DETECTION_FIELD_TO_SORT_PARAM_MAP: Record<
  string,
  keyof typeof DETECTION_SORT_BY_FIELD_PARAMS
> = {
  detectedAt: DETECTION_SORT_BY_FIELD_PARAMS.DETECTED_AT,
  emissionsRate: DETECTION_SORT_BY_FIELD_PARAMS.EMISSIONS_RATE,
  shortId: DETECTION_SORT_BY_FIELD_PARAMS.SHORT_ID,
  lastUpdatedTime: DETECTION_SORT_BY_FIELD_PARAMS.LAST_UPDATED,
}

export const DETECTION_TABLE_VIEWS = keymirror({
  INBOX: null,
  WIP: null,
  CLOSED: null,
})

export const DEFAULT_DATE_RANGE_START_VALUE = DATE_RANGE_FILTER_VALUES.today

export const DATE_FILTER_TOOLBOX_SETTINGS = {
  enableDateFilter: true,
  dateFilterOptions: {
    tooltipText: 'Filter by detection date',
    // ~3 months
    maxDaysRange: 93,
    defaultDateRangeValue: {
      startDate: DEFAULT_DATE_RANGE_START_VALUE,
    },
    getDateRangeFromFilter: filterValues => {
      const { detectedAfter, detectedBefore } = filterValues || {}
      return {
        startDate: detectedAfter as string,
        endDate: detectedBefore as string,
      }
    },
    transformSelectedRangeForFilter: value => {
      const [detectedAfter, detectedBefore] = value
      return { detectedAfter, detectedBefore }
    },
  } as DateFilterOptions,
}

/** List of fields available for search */
export const DETECTION_SEARCH_FIELDS = keymirror({
  siteId: null,
  screeningId: null,
  equipmentId: null,
  // TODO: need the backend support
  // detectionType https://sensorup.atlassian.net/browse/OXY-585
})

export const INBOX_SEARCH_FIELD_OPTIONS = [
  { value: DETECTION_SEARCH_FIELDS.equipmentId, label: 'Equipment ID' },
]

/** Field options for <SearchBar /> */
export const DETECTION_SEARCH_FIELD_OPTIONS = [
  { value: DETECTION_SEARCH_FIELDS.siteId, label: 'Site ID' },
  ...INBOX_SEARCH_FIELD_OPTIONS,
]

export const DETECTION_GALLERY_SEARCH_TOOLBOX_SETTINGS = {
  searchBarPlaceholder: 'Search detections',
  enableSearchFields: true,
  enableSearchDebounce: true,
  defaultSearchField: DETECTION_SEARCH_FIELDS.siteId,
  searchFieldsOptions: DETECTION_SEARCH_FIELD_OPTIONS,
}

export const CLOSE_ACTION_FIELD_NAMES = keymirror({
  explanation: null,
  type: null,
  correlatedEvent: null,
})

export const DETECTION_CURRENT_CATEGORIES = keymirror({
  WIP: null,
})

export const CORRELATE_EVENT_TYPES = {
  ...DETECTION_CURRENT_CATEGORIES,
}

export const ADDITIONAL_COMMENTS_CHAR_LIMIT = 225
export const ADDITIONAL_COMMENTS_CHAR_LIMIT_ADDITIONAL_COMMENT_OGI = 1200

export const ADDITIONAL_COMMENTS_LABEL = 'Additional comments'

export const DETECTION_EMISSION_UNIT_LABEL = 'kg/hr'

export const NO_SITE_PAGE_NAME = 'no-site'

export const EMISSION_RATE_MAX_DECIMALS = 2

export const DETECTION_DEFAULT_FILTER_KEYS_LIST = [
  'detectedAfter',
  'detectedBefore',
  'closed',
  'siteId',
]

export const QUBE_DEFAULT_PAGE_LINK = 'https://dashboard.qubeiot.com/'

export const KUVA_DEFAULT_PAGE_LINK = 'https://app.kuva.cloud/#'

export const LONG_PATH_DEFAULT_PAGE_LINK =
  'https://app.longpathtech.com/dashboard'

export const LAST_UPDATED_TIME_FIELD = 'lastUpdatedTime'

export const MANUAL_DETECTION_COORDINATE_DECIMAL_PRECISION = 6

export const ATTRIBUTION_EXPLANATION_SUGGESTION_TYPE = keymirror({
  OPS_COMMENT: null,
  VFB_DESCRIPTION: null,
})
