import { useMemo, useState, ReactElement } from 'react'
import { useToggle, usePrevious, useUpdateEffect } from 'react-use'
import _ from 'lodash'

// components
import { TextInput, MultiSelect, IconButton, Tooltip } from 'components/common'

import type { Option } from 'types/common'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import scss from './index.module.scss'

export const DEFAULT_SEARCH_ALL_OPTION = { label: 'All', value: 'all' }

export const SEARCH_DEBOUNCE_INTERVAL = 300

export const MIN_CHARACTERS_TO_SEARCH_ON_DEBOUNCE = 3

const SearchBar = ({
  defaultSearchField,
  onChange,
  fieldsOptions,
  className,
  searchBoxClassName,
  placeholder = 'Search ...',
  borderRight = false,
  enableSearchFields = false,
  fieldsLoading = false,
  enableSearchDebounce = false,
  minCharactersToSearch = MIN_CHARACTERS_TO_SEARCH_ON_DEBOUNCE,
}: {
  defaultSearchField?: string
  onChange: (payload: { value?: string; selectedFields?: string[] }) => void
  fieldsOptions?: Option[]
  className?: string
  searchBoxClassName?: string
  placeholder?: string
  borderRight?: boolean
  enableSearchFields?: boolean
  fieldsLoading?: boolean
  enableSearchDebounce?: boolean
  minCharactersToSearch?: number
}): ReactElement => {
  const [isActive, toggleActive] = useToggle(false)

  const [searchState, setSearchState] = useState(() => ({
    value: '',
    selectedFields: defaultSearchField ? [defaultSearchField] : [],
  }))

  const prevSearchInputValue = usePrevious(searchState.value)

  const onSearchStateChangeDebounced = useMemo(
    () =>
      _.debounce((state, prevSearchValue) => {
        if (
          // If there is no value now AND there was no value before...
          // (eg when user is switching the field before starting to search)
          (!state.value && !prevSearchValue) ||
          // ...or it's shorter than 3 chars...
          (state.value && state.value.length < minCharactersToSearch)
        )
          // ...do nothing
          return

        onChange(state)
      }, SEARCH_DEBOUNCE_INTERVAL),
    // NOTE: Don't need to put 'onChange' in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minCharactersToSearch]
  )

  useUpdateEffect(() => {
    // If we're using a backend search, we may want to debounce the user's input
    if (enableSearchDebounce) {
      onSearchStateChangeDebounced(searchState, prevSearchInputValue)
    } else {
      onChange(searchState)
    }
  }, [searchState])

  const selectedField = useMemo(
    () =>
      // Empty array means "All"
      _.isEmpty(searchState.selectedFields)
        ? DEFAULT_SEARCH_ALL_OPTION.value
        : _.first(searchState.selectedFields),
    [searchState.selectedFields]
  )

  const style = useMemo(
    () => ({
      borderRight: borderRight ? '1px solid #eee' : 'none',
      backgroundColor: isActive ? '#f3f3f3' : 'transparent',
    }),
    [borderRight, isActive]
  )

  const options = useMemo(
    () =>
      _.sortBy(
        [...(fieldsOptions ?? []), DEFAULT_SEARCH_ALL_OPTION],
        ['label']
      ),
    [fieldsOptions]
  )

  return (
    <div
      className={`${scss.search} ${className} d-flex align-items-center`}
      style={style}
    >
      <Tooltip
        placement={TOOLTIP_PLACEMENT.top}
        trigger={['hover']}
        overlay={<span>{placeholder}</span>}
      >
        <IconButton
          aria-label='active-search-icon'
          icon='MdSearch'
          size={18}
          onClick={() => toggleActive(!isActive)}
          data-testid='search-icon'
          className={scss.searchBtn}
        />
      </Tooltip>

      {isActive && (
        <>
          {enableSearchFields && !_.isEmpty(fieldsOptions) && (
            <MultiSelect
              isLoading={fieldsLoading}
              value={selectedField}
              options={options}
              className={scss.select}
              onChange={(option?: Option<string>) => {
                setSearchState(prevState => ({
                  ...prevState,
                  selectedFields:
                    option?.value === DEFAULT_SEARCH_ALL_OPTION.value
                      ? []
                      : [option.value],
                }))
              }}
              isMulti={false}
              placeholder='All'
              bgColour='#f3f3f3'
              isClearable={false}
            />
          )}
          <TextInput
            value={searchState.value}
            onChange={value => {
              setSearchState(prevState => ({ ...prevState, value }))
            }}
            changeOnBlur={false}
            className={`form-control ${scss.searchBox} ${searchBoxClassName}`}
            placeholder={placeholder}
            autoFocus
            testId='search-input'
          />
          {searchState.value && (
            <IconButton
              icon='MdClose'
              className={scss.clearIcon}
              onClick={() => {
                setSearchState(prevState => ({ ...prevState, value: '' }))
              }}
              aria-label='clear-search'
            />
          )}
        </>
      )}
    </div>
  )
}

export default SearchBar
