import { useRef, ReactElement, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useSize } from 'ahooks'
import styled from '@emotion/styled'

// components
import useImageCarousel from 'components/common/Image/useImageCarousel'
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'
import FileUploader from 'components/common/FileUploader'

// constants
import { FILE_MIME_TYPE_EXTENSIONS_MAPPING } from 'constants/common'

// utils
import { isJsonFormWidgetHidden } from 'helpers/formBuilder'

// types
import type { WidgetProps } from '@rjsf/utils'
import type { DataCollectionDeletableFormMedia } from 'types/issue'

import scss from './index.module.scss'

const IMAGES_MARGIN = 12
const IMAGES_PER_ROW = 6

const PhotosCount = styled.div<{ isLimitReached?: boolean }>`
  margin-bottom: 8px;
  font-size: 12px;
  color: ${({ theme, isLimitReached }) =>
    isLimitReached ? theme.danger : theme.secondary};
`

const ImageWidget = ({
  name,
  schema,
  uiSchema,
  value = [],
  readonly,
  required,
  disabled,
  formContext,
  rawErrors,
  onChange,
}: WidgetProps): ReactElement | null => {
  const { title, maxItems = 1 } = schema || {}
  const {
    imageResources,
    isPreview,
    fileUploadingState,
    mediaToUploadByField,
    setMediaToUploadByField,
  } = formContext || {}

  const toggleDelete = useCallback(
    (mediaKey: string, isDeleted: boolean) =>
      onChange(
        value.map(item => {
          if (item.mediaKey === mediaKey) return { ...item, isDeleted }
          return item
        })
      ),
    [value, onChange]
  )

  const { renderCarousel, renderImages } = useImageCarousel({
    imageResources,
    toggleDelete,
  })

  const isHidden = isJsonFormWidgetHidden(uiSchema)

  const ref = useRef(null)
  const size = useSize(ref)

  const imagesCount = useMemo(() => {
    const currentUploads = mediaToUploadByField?.[name] || []
    const currentUploadsCount = currentUploads?.length ?? 0
    const existingImagesCount =
      (value as DataCollectionDeletableFormMedia[] | undefined)?.filter(
        ({ isDeleted }) => !isDeleted
      ).length ?? 0

    return currentUploadsCount + existingImagesCount
  }, [mediaToUploadByField, name, value])

  const isLimitReached = imagesCount >= maxItems
  // Passing down 0 if the limit is reached
  const remainingUploadsLimit = Math.max(0, maxItems - imagesCount)

  const onUpload = useCallback(
    (files: File[]) => {
      setMediaToUploadByField?.(state => ({ ...state, [name]: files }))
    },
    [name, setMediaToUploadByField]
  )

  return isHidden ? null : (
    <div ref={ref}>
      <WidgetWrapper
        name={name}
        label={title}
        required={required}
        childrenContainerClassName={scss.container}
        rawErrors={rawErrors}
        isLarge={isPreview}
      >
        <PhotosCount isLimitReached={isLimitReached}>
          {imagesCount}/{maxItems} photos
        </PhotosCount>

        <FileUploader
          disabled={disabled || readonly}
          accept={FILE_MIME_TYPE_EXTENSIONS_MAPPING.image}
          placeholderText='Drop images here'
          isSingleFile={false}
          onChange={onUpload}
          fileUploadingState={fileUploadingState}
          maxFiles={remainingUploadsLimit}
          displayClickHereToUpload
        />

        {!_.isEmpty(value) &&
          !_.isEmpty(size) &&
          renderImages(
            value,
            size.width / IMAGES_PER_ROW - IMAGES_MARGIN,
            IMAGES_PER_ROW,
            {
              imageClassName: scss.squareCoverImage,
            }
          )}
        {renderCarousel()}
      </WidgetWrapper>
    </div>
  )
}

export default ImageWidget
