import { ReactElement, CSSProperties, ReactNode } from 'react'
import _ from 'lodash'

import scss from 'components/common/Form/index.module.scss'
import FieldLabel from './FieldLabel'
import FieldDescription from './FieldDescription'

const getFormLabelMargin = ({
  inline,
  hasDescription,
  isLarge,
}: {
  inline?: boolean
  hasDescription?: boolean
  isLarge?: boolean
}) => {
  switch (true) {
    case inline:
      return 'mb-0'
    case hasDescription:
      return 'mb-1'
    default:
      return isLarge ? 'mb-2' : 'mb-1'
  }
}

export type FieldWrapperProps = {
  description?: string
  label?: string
  required?: boolean
  groupOptionStyle?: boolean
  name?: string
  style?: CSSProperties
  children?: ReactNode
  addon?: ReactNode
  inline?: boolean
  labelClassName?: string
  labelContainerClassName?: string
  childrenContainerClassName?: string
  containerClassName?: string
  tooltip?: string
  checkable?: boolean
  onChecked?: (name: string, isChecked: boolean) => void
  errorClassName?: string
  ignoreError?: boolean
  isLarge?: boolean
  hasError?: boolean
  metaError?: string
  preserveSpaceForError?: boolean
}

const FieldWrapper = ({
  name,
  label = '',
  required = false,
  groupOptionStyle = false,
  description = '',
  tooltip,
  style = {},
  children,
  addon,
  inline = false,
  checkable = false,
  onChecked = _.noop,
  labelClassName = 'form-label',
  labelContainerClassName = '',
  childrenContainerClassName = '',
  containerClassName = '',
  errorClassName,
  ignoreError = false,
  isLarge,
  hasError,
  metaError,
  preserveSpaceForError,
}: FieldWrapperProps): ReactElement => (
  <>
    <div
      className={
        inline
          ? `d-flex justify-content-between align-items-center ${containerClassName}`
          : containerClassName
      }
    >
      <div className={labelContainerClassName}>
        {label && (
          <FieldLabel
            name={name}
            label={label}
            required={required}
            tooltip={tooltip}
            checkable={checkable}
            onChecked={onChecked}
            isLarge={isLarge}
            labelClassName={`${labelClassName} ${getFormLabelMargin({
              inline,
              hasDescription: !!description,
              isLarge,
            })} ${hasError ? 'is-invalid' : ''}`}
          />
        )}
        {description && (
          <FieldDescription isLarge={isLarge} hasError={hasError}>
            {description}
          </FieldDescription>
        )}
      </div>
      <div
        className={`${
          groupOptionStyle ? 'groupOptionContent' : inline ? '' : 'w-100'
        } position-relative ${
          hasError ? 'is-invalid' : ''
        } ${childrenContainerClassName}`}
        style={style}
      >
        {children}
        {addon}
      </div>
    </div>

    {preserveSpaceForError && !hasError && !ignoreError && (
      <div className={scss.errorStub} />
    )}

    {hasError && !ignoreError && (
      <div className={`${scss.error} ${errorClassName}`}>{metaError}</div>
    )}
  </>
)

export default FieldWrapper
