import { ReactElement } from 'react'
import _ from 'lodash'

const SUBSTRING_LENGTH = 500

const ErrorMessage = ({
  error,
}: {
  error?: {
    name?: string
    message: string
  }
}): ReactElement => {
  if (!error) return <></>

  const { name, message } = _.isObject(error) ? error : { message: error }

  const errorMessage = _.compact([name, message]).join(':')

  return (
    <div className='alert alert-danger'>
      {errorMessage.length > SUBSTRING_LENGTH
        ? `${errorMessage.substring(0, 500)}...`
        : errorMessage}
    </div>
  )
}

export default ErrorMessage
