import { useMemo } from 'react'
import _ from 'lodash'

import type { Filters, FilterSpec } from 'types/filter'

import { useTimezone } from 'hooks'

import { prepareFiltersForDetectionsQuery } from 'app/MissionControlMethaneSolution/helpers/detection'
import { useAuthStateValue } from 'contexts'
import { getCustomizedFiltersKeys } from 'helpers/filter'

const getAssetPropertiesFilters = ({
  preparedFilters,
  assetFiltersKeys,
}: {
  preparedFilters: Filters
  assetFiltersKeys?: string[]
}) => {
  const propertyFilters = _.map(
    _.pick(preparedFilters, assetFiltersKeys || []),
    (value, property) => ({ property, valueJson: value })
  )
  return _.isEmpty(propertyFilters) ? undefined : { propertyFilters }
}

/** Prepares filters for a query, eg replaces some values with enums, etc  */
const useDetectionQueryFilters = ({
  filters,
  customFiltersSpecs,
  searchQuery,
  selectedSearchFields,
  assetFiltersKeys,
}: {
  filters?: Filters
  customFiltersSpecs?: FilterSpec[]
  searchQuery?: string
  selectedSearchFields?: string[]
  assetFiltersKeys?: string[]
}): Filters => {
  const { timezone } = useTimezone()

  const { sessionUserGroup } = useAuthStateValue()

  const customizedFiltersKeys = useMemo(
    () => getCustomizedFiltersKeys(customFiltersSpecs),
    [customFiltersSpecs]
  )

  const preparedFilters = useMemo(
    () =>
      prepareFiltersForDetectionsQuery({
        filters: _.pick(filters, customizedFiltersKeys),
        customFiltersSpecs,
        timezone,
        searchQuery,
        selectedSearchFields,
      }),
    [
      filters,
      customizedFiltersKeys,
      customFiltersSpecs,
      timezone,
      searchQuery,
      selectedSearchFields,
    ]
  )

  return useMemo(() => {
    const assetFilters = getAssetPropertiesFilters({
      preparedFilters,
      assetFiltersKeys,
    })
    return {
      ..._.omit(preparedFilters, assetFiltersKeys || []),
      ...(sessionUserGroup && { group: [sessionUserGroup] }),
      ...(assetFilters && { assetFilters }),
    }
  }, [assetFiltersKeys, preparedFilters, sessionUserGroup])
}

export default useDetectionQueryFilters
