import type { DetectionType } from 'app/MissionControlMethaneSolution/types/detection'
import { DetectionStatus } from 'app/MissionControlMethaneSolution/types/graphql'
import { DETECTION_TYPES } from './common'
import { SourceAttributionType } from '../types/graphql'

export const EMISSION_OBSERVATION_TYPE_ICONS_MAPPING = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'MethaneCMSIcon',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'MethaneDroneIcon',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'MethaneFlyOverIcon',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'MethaneSatelliteIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'MethaneOGIIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'FaWalking',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'MethaneManualIcon',
  [DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN]: 'MethaneVFBIcon',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'MethaneUnknownIcon',
}

export const EMISSION_OBSERVATION_TYPE_TOOLTIPS = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'Continuous Monitoring System',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'Drone',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'Optical Gas Imaging',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'On-site Walk Down',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'Flyover',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'Satellite',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'Manual',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'Unknown',
  [DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN]: 'VFB',
}

export const EMISSION_OBSERVATION_ATTRIBUTION_TYPES = SourceAttributionType

export const OBSERVATION_WITH_OGI_WORK_ITEM_STATUSES_LIST = [
  DetectionStatus.DetectionStatusOgiRequested,
  DetectionStatus.DetectionStatusOgiScheduled,
  DetectionStatus.DetectionStatusOgiCompleted,
]

export const DETECTION_TYPES_TO_OMIT_ON_MAP: DetectionType[] = [
  DETECTION_TYPES.DETECTION_TYPE_OGI,
]
