import { ReactElement, MutableRefObject, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { ProgressBar } from 'primereact/progressbar'
import styled from '@emotion/styled/macro'
import { CSSTransition } from 'react-transition-group'
import { useTheme } from '@emotion/react'
import { useLoading } from 'app/MissionControlMethaneSolution/hooks/detection'

export const LOADING_MESSAGE = 'Loading...'

export const BAR_CONTAINER_TEST_ID = 'barContainer'

const PROGRESS_BAR_HEIGHT = 2

const BarContainer = styled.div<{ offsetTop: number }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ offsetTop }) => offsetTop}px;
  z-index: 1;
`

const StyledProgressBar = styled(ProgressBar)`
  height: ${PROGRESS_BAR_HEIGHT}px;
`

const Overlay = styled.div<{ offsetTop: number }>`
  position: absolute;
  top: ${({ offsetTop }) => offsetTop}px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme['secondary-900']};
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
`

const TableProgressBar = ({
  tableRef,
  overlayDelay = 7000,
}: {
  tableRef: MutableRefObject<DataTable<[]> | null>
  overlayDelay?: number
}): ReactElement => {
  const theme = useTheme()
  // Display the overlay if loading is shown for longer than N seconds
  const { showLoader: isDelayPassed } = useLoading({ delayMs: overlayDelay })

  const tableNode = tableRef.current?.getTable()
  const theadHeight = tableNode?.tHead?.clientHeight
  // Making an offset from the top to display the progressbar under <thead>
  const offsetTop = theadHeight ?? 0
  const overlayRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <BarContainer data-testid={BAR_CONTAINER_TEST_ID} offsetTop={offsetTop}>
        <StyledProgressBar mode='indeterminate' color={theme.primary} />
      </BarContainer>

      <CSSTransition
        in={isDelayPassed}
        timeout={700}
        classNames='fadeIn'
        mountOnEnter
        unmountOnExit
      >
        <Overlay ref={overlayRef} offsetTop={offsetTop + PROGRESS_BAR_HEIGHT}>
          <span>{LOADING_MESSAGE}</span>
        </Overlay>
      </CSSTransition>
    </>
  )
}

export default TableProgressBar
