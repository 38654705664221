import { useMemo, useRef } from 'react'
import { useUpdateEffect, usePrevious } from 'react-use'
import {
  RecoilValue,
  useRecoilValueLoadable,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil'
import log from 'helpers/log'

const useRecoilLoadableResult = <T>(
  recoilValue: RecoilValue<T>
): {
  data?: T
  stabilizedData?: T
  error?: Error
  loading: boolean
  isFirstRequestDone: boolean
  invalidateCache: () => void
} => {
  // This ref is often used in tables to show the correct spinner/loader
  const isFirstRequestDoneRef = useRef(false)
  const recoilLoadable = useRecoilValueLoadable(recoilValue)
  const refresh = useRecoilRefresher(recoilValue)
  const { state, contents } = recoilLoadable

  const loading = state === 'loading'

  useUpdateEffect(() => {
    if (contents) isFirstRequestDoneRef.current = true
  }, [contents])

  const data = useMemo(
    () => (state === 'hasValue' ? contents : undefined),
    [contents, state]
  )

  const prevData = usePrevious(data)
  // While the data is loading, it returns the previous data instead of 'undefined'
  const stabilizedData = useMemo(
    () => (loading ? prevData : data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, data]
  )

  const error = useMemo(() => {
    const dataError = state === 'hasError' ? contents : undefined
    if (dataError) {
      log.error(dataError)
    }
    return dataError
  }, [contents, state])

  return {
    data,
    stabilizedData,
    error,
    loading,
    isFirstRequestDone: isFirstRequestDoneRef.current,
    invalidateCache: refresh,
  }
}

export default useRecoilLoadableResult
