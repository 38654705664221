import _ from 'lodash'

import { MUTATION_RESPONSE_ERRORS_FIELDS } from 'constants/graphql'
import {
  getQuery,
  getMutationQuery,
  getArgs,
  getVariables,
  getEdges,
  getPageInfo,
  getRelayEdgesData,
  SuGraphqlError,
  getMutationErrorMessage,
  convertVariablesToArgs,
} from 'helpers/graphql'
import log, { reportErrors } from 'helpers/log'
import { isDevEnvironment } from 'helpers/utils'

import type { Payload } from 'types/common'
import type { MutationError, PageInfo } from 'types/graphql'
import type { Entity } from 'types/entity'
import type { QueryParams, Fields, SelectedFields } from 'types/services'

import GraphqlApi from './graphql'

type GetFieldsFn = ({ omitFields, pickFields }: SelectedFields) => Payload

const DEFAULT_IDENTIFIER = 'id'

const DEFAULT_IDENTIFIER_FORMAT = 'ID!'

const getFnName = ({
  queryDomain,
  queryName,
  separator = '.',
}: {
  queryDomain?: string
  queryName?: string | null
  separator?: string
}) => _.compact([queryDomain, queryName]).join(separator)

type QueryProp<T> = {
  queryParams?: QueryParams<T>
} & SelectedFields

type GetQueryFn<T> = ({
  queryParams,
  omitFields,
  pickFields,
}: QueryProp<T>) => Payload

type GetQueryByIdFn = ({ omitFields, pickFields }: SelectedFields) => Payload

export type RelayStyleData<T> = {
  data: T[]
  error?: string
  query?: string
  pageInfo: PageInfo
}

export type ListRelayStyleData<T> = {
  queryDomain: string
  fnName?: string
  onBatch?: (data: T[]) => void
  getQueryFn: GetQueryFn<T>
  enableLoadMore?: boolean
  queryParams?: QueryParams<unknown>
  initPageInfo?: PageInfo
  abortController?: AbortController
  queryDisplayName?: string
} & SelectedFields

export const listRelayStyleData = async <T>({
  queryDomain,
  fnName,
  onBatch,
  getQueryFn,
  enableLoadMore = true,
  queryParams = {},
  initPageInfo,
  abortController,
  omitFields,
  pickFields,
  queryDisplayName,
}: ListRelayStyleData<T>): Promise<RelayStyleData<T>> => {
  let pageInfo = (initPageInfo ?? {}) as PageInfo
  let response
  let data = [] as T[]
  let error
  let query
  do {
    const { endCursor } = pageInfo

    const queryParamsWithPageInfo = {
      ...queryParams,
      ...(endCursor && { after: endCursor }),
    }

    const queryPayload = getQueryFn({
      queryParams: queryParamsWithPageInfo,
      omitFields,
      pickFields,
    })

    query = getQuery(queryPayload, queryDisplayName ?? fnName)

    response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      abortController,
      variables: queryParamsWithPageInfo,
    })
    error = response.error || null

    const responseData = _.get(response, fnName) ?? response
    const batchedData = getRelayEdgesData<T>(responseData)
    if (onBatch) {
      onBatch(batchedData)
    }
    data = _.concat(data, batchedData)
    log.info(`[${queryDomain}]: Received ${data.length || 0} lines of data`)
    pageInfo = responseData.pageInfo || {}
  } while (enableLoadMore && pageInfo.hasNextPage)

  if (pageInfo.hasNextPage && _.isEmpty(data)) {
    log.warn(`[${queryDomain}] The current page has no data`)
  }

  return { data, error, pageInfo, query }
}

export const getResponseData = ({
  response,
  path,
  ignoreError = false,
  ...extra
}: {
  response: { error: string }
  path: string | Fields
  ignoreError?: boolean
}): { errors?: MutationError[] } => {
  const { error } = response
  const data = _.get(response, path)
  if (error && !ignoreError) {
    reportErrors(new SuGraphqlError({ error, ...extra }), extra)
    if (_.isEmpty(data) && !isDevEnvironment()) {
      throw new SuGraphqlError({ error })
    }
  }
  return data
}

export const deserializeEntityData = (data: Entity): Entity => {
  if (!data) return {} as Entity

  return data
}

const DEFAULT_GET_ENTITY_QUERY_NAME = 'byId'

const DEFAULT_LIST_ENTITIES_QUERY_NAME = 'all'

type GetGraphqlQuery = {
  getFieldsFn: GetFieldsFn
  queryDomain?: string
  queryName?: string | null
  payload?: Payload
  variables?: Payload<string | string[]>
  resolverVariables?: Payload<string | string[]>
}

const getQueryWithName = (
  query: Payload,
  queryName?: GetGraphqlQuery['queryName']
) => (queryName ? _.set({}, queryName, query) : query)

export const getGraphqlQuery =
  ({
    queryDomain,
    getFieldsFn,
    payload,
    resolverVariables,
    variables,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
  }: GetGraphqlQuery) =>
  ({ omitFields, pickFields }: SelectedFields = {}): Record<
    string,
    Payload
  > => {
    const queryVariables = resolverVariables || variables

    const queryParams = queryVariables
      ? convertVariablesToArgs(queryVariables)
      : payload

    const queryWithName = getQueryWithName(
      {
        ...getArgs(queryParams),
        ...getFieldsFn({ omitFields, pickFields }),
      },
      queryName
    )

    return {
      ...getVariables(variables),
      ...getQueryWithName(queryWithName, queryDomain),
    }
  }

export const getEntityQuery =
  ({
    identifier = DEFAULT_IDENTIFIER,
    identifierFormat = DEFAULT_IDENTIFIER_FORMAT,
    ...rest
  }: Omit<GetGraphqlQuery, 'payload'> & {
    identifier?: string | null
    identifierFormat?: string
  }) =>
  ({ omitFields, pickFields }: Partial<SelectedFields> = {}): Record<
    string,
    Payload
  > => {
    return getGraphqlQuery({
      variables: identifier ? { [identifier]: identifierFormat } : undefined,
      ...rest,
    })({ omitFields, pickFields })
  }

export const getEntitiesQuery =
  <T>({
    queryDomain,
    getFieldsFn,
    variables,
    resolverVariables,
    enablePaging = true,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
  }: Omit<GetGraphqlQuery, 'payload'> & {
    enablePaging?: boolean
  }) =>
  ({ queryParams, omitFields, pickFields }: QueryProp<T>): Payload => {
    // - 'variables' are used on the very top: 'query Something(...)'
    // - 'resolverVariables' are used in a resolver, eg 'all(...)'
    // So if you're querying some fields with arguments,
    // 'resolverVariables' and 'variables' could be different
    const queryVariables = resolverVariables || variables

    const args = queryVariables
      ? convertVariablesToArgs(queryVariables)
      : queryParams

    const queryWithName = getQueryWithName(
      {
        ...getArgs(args),
        ...getEdges(getFieldsFn({ omitFields, pickFields })),
        ...(enablePaging && getPageInfo()),
      },
      queryName
    )

    return {
      ...getVariables(variables),
      ...getQueryWithName(queryWithName, queryDomain),
    }
  }

type PostProcessFn<T> = (data: T) => T

export const getGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    omitFields,
    pickFields,
    queryDisplayName,
    postProcessFn,
    fnName,
    queryName,
    ignoreError,
  }: {
    queryDomain: string
    getQueryFn: GetQueryFn<T>
    queryDisplayName?: string
    postProcessFn?: PostProcessFn<T>
    fnName?: string
    queryName?: string | null
    ignoreError?: boolean
  } & SelectedFields) =>
  async (variables?: QueryParams<T>): Promise<T> => {
    const functionName =
      fnName ??
      getFnName({
        queryDomain,
        queryName,
      })

    const query = getQuery(
      getQueryFn({ ...variables, omitFields, pickFields }),
      queryDisplayName ?? _.camelCase(`get_${functionName}`)
    )
    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      variables,
      ignoreError,
      fnName: functionName,
    })

    const data = getResponseData({
      response,
      path: functionName,
      query,
      variables,
      ignoreError,
    }) as T
    return _.isFunction(postProcessFn) ? postProcessFn(data) : data
  }

export const getEntityGraphql =
  <T>({
    postProcessFn = deserializeEntityData,
    identifier = DEFAULT_IDENTIFIER,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
    ...rest
  }: {
    queryDomain: string
    getQueryFn: GetQueryByIdFn
    queryDisplayName?: string
    postProcessFn?: PostProcessFn<T> | null
    queryName?: string | null
    identifier?: string
  } & SelectedFields) =>
  async (
    args: string | Payload<unknown>,
    options?: SelectedFields
  ): Promise<T> => {
    const queryArgs = _.isObject(args) ? args : { [identifier]: args }

    return getGraphql({
      ...rest,
      postProcessFn,
      queryName,
      ...options,
    })(queryArgs)
  }

export const listEntitiesGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    queryDisplayName,
    defaultOmitFields,
    defaultPickFields,
    isSingleEntityPostProcessFn = true,
    postProcessFn = deserializeEntityData,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
    enableLoadMore,
    defaultQueryParams,
    ignoreError,
  }: {
    defaultOmitFields?: Fields
    defaultPickFields?: Fields
    isSingleEntityPostProcessFn?: boolean
    postProcessFn?: PostProcessFn<T>
    queryName?: string | null
    ignoreError?: boolean
    defaultQueryParams?: ListRelayStyleData<T>['queryParams']
  } & ListRelayStyleData<T>) =>
  async ({
    omitFields,
    pickFields,
    queryParams,
    ...rest
  }: Omit<
    ListRelayStyleData<T>,
    'getQueryFn' | 'queryDomain' | 'fnName'
  > = {}): Promise<RelayStyleData<T>> => {
    const fnName = getFnName({ queryDomain, queryName })

    const response = await listRelayStyleData<T>({
      enableLoadMore,
      ...rest,
      queryDomain,
      fnName,
      getQueryFn,
      queryDisplayName,
      pickFields: pickFields ?? defaultPickFields,
      omitFields: omitFields ?? defaultOmitFields,
      queryParams: { ...defaultQueryParams, ...queryParams },
    })

    const { query } = response

    const data = getResponseData({
      response,
      path: 'data',
      fnName,
      query,
      ignoreError,
    }) as T[]

    return postProcessFn
      ? {
          ...response,
          data: isSingleEntityPostProcessFn
            ? _.map(data, postProcessFn)
            : postProcessFn(data, response),
        }
      : response
  }

export const getResponseError = ({
  noReturnData = false,
  data,
  systemError,
  userError,
  noDataError,
  keepUserErrorMessage = false,
}: {
  noReturnData?: boolean
  data?: unknown
  systemError?: string
  userError?: string
  noDataError?: string
  keepUserErrorMessage?: boolean
}): string | undefined => {
  const error = systemError || userError || noDataError

  if (error) {
    log.error('Server error: ', error)
  }
  const overrideErrorMessage =
    !systemError && keepUserErrorMessage && userError
      ? userError
      : 'Something went wrong.'

  if (noReturnData) {
    if (error) {
      return overrideErrorMessage
    }
  } else if (error || _.isNil(data)) {
    return overrideErrorMessage
  }
  return undefined
}

export type MutateEntity<T = unknown> = {
  queryDomain: string
  fnName: string
  mutationName?: string
  variableFormat?: string
  responseFields?: Payload
  argsKey?: string | null
  identifier?: string
  responsePath?: Fields
  withIdentifier?: boolean
  ignoreError?: boolean
  noReturnData?: boolean
  keepUserErrorMessage?: boolean
  postProcessFn?: PostProcessFn<T>
  variables?: Payload<string>
}

export const mutateEntity =
  <T = unknown>({
    queryDomain,
    fnName,
    mutationName,
    variables,
    variableFormat,
    responseFields = {},
    argsKey = 'input',
    identifier = DEFAULT_IDENTIFIER,
    responsePath = [],
    withIdentifier = true,
    ignoreError = false,
    noReturnData = false,
    postProcessFn = deserializeEntityData,
    keepUserErrorMessage = false,
  }: MutateEntity<T>) =>
  async (
    id?: string | null,
    args?: QueryParams,
    fieldsWithArguments?: QueryParams
  ): Promise<T | { data: T; error?: string }> => {
    const fields = {
      ..._.merge({}, responseFields, fieldsWithArguments || {}),
      ...MUTATION_RESPONSE_ERRORS_FIELDS,
    }
    const argsPayload = withIdentifier ? { [identifier]: id, ...args } : args
    const query = getMutationQuery({
      fields,
      args: argsPayload,
      // variables is the multiple combinations of {variableKey:variableFormat}
      // after refactoring mutations to support variables, retire the variableFormat and variableKey
      variables,
      variableFormat,
      variableKey: argsKey,
      mutationName: mutationName || fnName,
    })

    const fetchVariables =
      argsKey && !variables ? { [argsKey]: argsPayload } : argsPayload

    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      ignoreError,
      variables: fetchVariables,
    })

    const data = getResponseData({
      response,
      ignoreError,
      query,
      path: [fnName, ...responsePath],
    })

    const { error, code } = response

    const { errors } = _.get(response, [fnName]) || {}

    const shouldResponseDataEmpty = _.isEmpty(responseFields) || noReturnData

    const invalidData = shouldResponseDataEmpty ? false : _.isNil(data)

    const responseError = getResponseError({
      noReturnData: shouldResponseDataEmpty,
      data,
      systemError: error,
      userError: getMutationErrorMessage(errors),
      noDataError: invalidData ? 'No data returned' : '',
      keepUserErrorMessage,
    })

    const deserializedData = (
      data && postProcessFn ? postProcessFn(data) : data
    ) as T

    if (ignoreError && !invalidData) {
      return { error: responseError, data: deserializedData }
    }

    if (responseError) {
      throw new SuGraphqlError({
        code,
        error: responseError,
      })
    }

    return deserializedData
  }
