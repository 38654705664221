import _ from 'lodash'
import { useCallback, useMemo } from 'react'

// utils
import { useRecoilLoadableResult } from 'hooks'
import { assetSitesOptionsState } from 'app/MissionControlMethaneSolution/recoilStore/assetTypeStore'

// components
import FilterDropdown, {
  FilterDropdownProps,
} from 'components/common/List/FilterBuilder/FilterDropdown'

import type { Option } from 'types/common'

export const getAssetOptions = (options: unknown[]): Option[] => {
  if (_.isEmpty(options)) return []

  const firstOption = _.first(options as [])
  return (
    _.isString(firstOption)
      ? _.map(options, value => ({ value, label: value }))
      : options
  ) as Option[]
}

const AssetSitesFilterAsyncDropdown = ({
  value,
  onChange,
  ...rest
}: {
  value: Option[] | string[]
  title: string
} & FilterDropdownProps) => {
  const { data: assetSiteOptions, loading: isAssetSitesListLoading } =
    useRecoilLoadableResult(assetSitesOptionsState)

  const selectedAssetOptions = useMemo(() => {
    return getAssetOptions(value as [])
  }, [value])

  const selectedAssetOptionsValues = useMemo(
    () => _.map(selectedAssetOptions, 'value'),
    [selectedAssetOptions]
  )

  const onOptionsChange = useCallback(
    (newOptions?: Option[]) => {
      const newValues = _.map(newOptions, option =>
        _.pick(option, ['value', 'label'])
      )
      onChange(newValues)
    },
    [onChange]
  )

  return (
    <FilterDropdown
      {..._.omit(rest, ['filters'])}
      onChange={onOptionsChange}
      isMulti
      options={
        isAssetSitesListLoading ? selectedAssetOptions : assetSiteOptions
      }
      value={selectedAssetOptionsValues}
      isLoading={isAssetSitesListLoading}
    />
  )
}

export default AssetSitesFilterAsyncDropdown
