// libraries
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'

// components
import { AdvanceTooltip } from 'components/common/Tooltip'

import type { AdvanceTooltipProps } from 'components/common/Tooltip/types'

import scss from './index.module.scss'

type TruncatingTextWithTooltipProps = AdvanceTooltipProps & {
  className?: string
  maxLines?: number
}

export const TruncatingTextWithTooltip: FC<
  PropsWithChildren<TruncatingTextWithTooltipProps>
> = ({ title, children, className = '', maxLines = 1, ...rest }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const checkTextOverflow = useCallback(() => {
    const { current } = textRef
    if (current) {
      const isOverflowing =
        maxLines > 1
          ? current.scrollHeight > current.clientHeight
          : current.scrollWidth > current.clientWidth

      setIsTextOverflowing(isOverflowing)
    }
  }, [maxLines])

  const debouncedCheckTextOverflow = useMemo(
    () => _.debounce(checkTextOverflow, 100),
    [checkTextOverflow]
  )

  useEffect(() => {
    const currentElement = textRef.current
    const resizeObserver = new ResizeObserver(debouncedCheckTextOverflow)

    if (currentElement) {
      resizeObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        resizeObserver.unobserve(currentElement)
      }
      resizeObserver.disconnect()
    }
  }, [debouncedCheckTextOverflow])

  const getClassName = useMemo(() => {
    return maxLines === 1 ? scss.singleLineTruncate : scss.multiLineTruncate
  }, [maxLines])

  const textStyle = useMemo(
    () => ({
      WebkitLineClamp: maxLines,
    }),
    [maxLines]
  )

  const handleMouseEnter = useCallback(() => setIsHovered(true), [])
  const handleMouseLeave = useCallback(() => setIsHovered(false), [])

  const visible = isTextOverflowing && isHovered

  return (
    <div
      className={`overflow-hidden ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AdvanceTooltip {...rest} visible={visible} title={title}>
        <div
          ref={textRef}
          className={`${getClassName} ${className}`}
          style={textStyle}
        >
          {children}
        </div>
      </AdvanceTooltip>
    </div>
  )
}
